<template>
  <div class="set-goal-step pt-32">
    <div class="set-goal-step__container">
      <div class="set-goal-step__headline flex align-center mb-24">
        <img
          class="occupation-step__avatar w-48 h-48 radius-50 mr-8"
          :src="require('@images/avatars/avatar.png')"
          alt="Anatoli, the author of SQL Habit" />

        <span
          class="h2"
          v-html="$options.filters.md('What\'s your goal? :dart:')" />
      </div>

      <div class="set-goal-step__description">
        How Data Analysis with SQL will be useful for you? <br class="hidden-xs"> What do you want to achieve?
      </div>

      <div class="set-goal-step__goals">
        <a
          href="#"
          class="set-goal-step__goal btn btn--selectable"
          v-bind:class="{ 'btn--selected': goalOptionClass(goal) }"
          v-for="goal in Object.keys(availableGoals)"
          v-bind:key="goal"
          v-on:click.prevent="toggleGoal(goal)">

          {{ availableGoals[goal] }}
        </a>

        <input
          type="text"
          placeholder="Other"
          name="other-goal"
          class="set-goal-step__goal-input"
          data-value="other"
          v-model="otherValue"
          v-on:change="handleOtherInput">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      availableGoals: {
        "at-work": "Build my own dashboards at work",
        "job-interview": "Get ready for an Interview",
        "business": "Measure my own business"
      },
      otherValue: null,
      values: {}
    }
  },
  props: {
    goals: {
      type: Object,
      default: () => {}
    },
    otherGoal: {
      type: String,
      default: null
    }
  },
  methods: {
    goalOptionClass(goal) {
      return this.values[goal];
    },
    toggleGoal(goal) {
      this.$set(this.values, goal, !this.values[goal]);

      this.$emit("toggle-set-goals", this.values);
    },
    handleOtherInput() {
      this.$emit("toggle-other-goal", this.otherValue);
    }
  },
  mounted() {
    this.values = this.goals;
    this.otherValue = this.otherGoal;
  }
}
</script>

<style lang="scss">
.set-goal-step {
  max-width: 720px;

  &__description {
    margin-bottom: $px32;

    @include font-large;
  }

  &__goals {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $px32;
  }

  &__goal {
    margin-bottom: $px16;

    &:not(:last-child) {
      margin-right: $px16;
    }
  }

  &__goal-input {
    margin-bottom: $px16;
    padding: $px8;

    outline: none;
    appearance: none;
    border: 0;
    border-bottom: $px1 solid $grey-9;

    color: $grey-1;

    &::placeholder {
      @include font-btn;
      color: $grey-7;
    }
  }
}
</style>
