<template>
  <div
    class="mock-interview"
    :class="{
      'mock-interview--full-screen': fullScreen
    }"
    ref="container">
    <header class="mock-interview__header">
      <Logo class="mock-interview__header-logo" />

      <div class="mock-interview__full-screen-control">
        <ShrinkIcon
          v-if="fullScreen"
          class="mock-interview__full-screen-icon mock-interview__expand-icon"
          @click="exitFullScreen" />
        <ExpandIcon
          v-else
          class="mock-interview__full-screen-icon mock-interview__shrink-icon"
          @click="enterFullScreen" />
      </div>
    </header>

    <div class="mock-interview__container">
      <header
        v-if="!singleExercise"
        class="mock-interview__items-header">
        <div class="mock-interview__items-counter">
          <strong>Question {{ currentItemPosition }} / {{ itemsCount }}</strong>
        </div>

        <div class="mock-interview__timer">
          {{ formattedTimeLeft }}
        </div>
      </header>

      <div class="mock-interview__interview-item">
        <MockInterviewExercise
          :interviewItem="currentItem"
          :mockInterviewId="mockInterviewId"
          :fullScreen="fullScreen"
          :showSkipLink="!singleExercise"
          v-on:exercise-finished="nextInterviewItem"
          :initialQuery="prevSolutionQuery" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Logo from "@images/svgs/header_logo.svg";
import ExpandIcon from "@images/font_awesome/expand-arrows-alt-light.svg";
import ShrinkIcon from "@images/font_awesome/compress-arrows-alt-light.svg";
import MockInterviewExercise from "components/mock-interview/exercise";
import Sentry from "modules/sentry";

export default {
  data() {
    return {
      mockInterviewId: window.state.mockInterviewId,
      items: window.state.interviewItems,
      currentPosition: window.state.currentPosition,
      timeLeft: window.state.timeLeft,
      timerInterval: null,
      fullScreen: false,
      prevSolutionQuery: decodeURIComponent(window.state.prevSolutionQuery.replace(/\+/g, " "))
    }
  },
  watch: {
    timeLeft(val, oldVal) {
      if(val <= 0) {
        this.finish();
      }
    },
  },
  computed: {
    singleExercise() {
      return this.items.length === 1;
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;

      if(timeLeft > 0) {
        const minutes = Math.floor(timeLeft / 60);
        let seconds = Math.floor(timeLeft % 60);

        if (seconds < 10) {
          seconds = `0${seconds}`;
        }

        return `${minutes}:${seconds}`;
      } else {
        return "Time is up";
      }
    },
    currentItem() {
      return this.items[this.currentPosition - 1];
    },
    currentItemType() {
      return this.items[this.currentPosition - 1].type;
    },
    currentItemPosition() {
      return this.currentPosition;
    },
    itemsCount() {
      return this.items.length;
    },
  },
  methods: {
    handleFullscreenChange() {
      this.fullScreen = !!document.fullscreenElement;
    },
    nextInterviewItem() {
      if(this.currentPosition === this.items.length) {
        this.finish();
      } else {
        this.currentPosition += 1;

        window.scrollTo(0, 0);

        if(this.fullScreen) {
          this.$refs.container.scrollTo(0, 0);
        }
      }
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timeLeft -= 1), 1000);
    },
    finish() {
      let apiPath = `/mock-interviews/${this.mockInterviewId}/finish.json`;

      axios.patch(apiPath, {}, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        window.location = window.state.finishPagePath;
      }).catch(error => {
        Sentry.captureException(error);
      });
    },
    enterFullScreen() {
      const container = this.$refs.container;

      container.requestFullscreen({ navigationUI: "hide" }).catch((error) => {
        this.fullScreen = false;

        Sentry.captureException(error);
      });
    },
    exitFullScreen() {
      if (!document.fullscreenElement) {
        return;
      }

      document.exitFullscreen().catch((error) => {
        console.error(error)

        Sentry.captureException(error);
      })
    }
  },
  components: {
    Logo,
    ExpandIcon,
    ShrinkIcon,
    MockInterviewExercise
  },
  mounted() {
    if(!this.singleExercise) {
      this.startTimer();
    }

    document.addEventListener("fullscreenchange", this.handleFullscreenChange)
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.handleFullscreenChange)
  },
}
</script>

<style lang="scss">
.mock-interview {
  $root: &;

  position: relative;
  padding-bottom: $px48;

  &__container {
    position: relative;
    padding-bottom: $px64;

    @include breakpoint($sm) {
      padding-bottom: 0;
    }

    @include breakpoint($md) {
      padding-top: $px32;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: $px24 0;
  }

  &__items-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: $px8;
    margin-bottom: $px24;

    border-bottom: $px1 solid $grey-9;
  }

  &__full-screen-icon {
    width: $px24;
    height: $px24;

    color: $grey-8;

    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      color: $grey-6;

      transform: scale(1.2);
    }
  }

  &:fullscreen {
    padding: $px24;
    overflow-y: scroll;
    position: relative;
    z-index: 1;

    background-color: $white;
  }
}
</style>
