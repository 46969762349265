<template>
  <div
    class="tab-body"
    :class="{
      'tab-body--active': active
    }">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>
