<template>
  <div class="confirmation-popup">
    <div class="confirmation-popup__container">
      <div class="confirmation-popup__copy">
        {{ copy }}
      </div>

      <div class="confirmation-popup__actions">
        <a
          href="#"
          class="confirmation-popup__cancel btn btn--xs btn--secondary"
          @click.prevent="handleCancel">
          Cancel
        </a>
        <a
          href="#"
          class="confirmation-popup__confirm btn btn--xs"
          @click.prevent="handleConfirm">
          Yes
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    copy: String
  },
  methods: {
    handleConfirm() {
      this.$emit("submit", true);
    },
    handleCancel() {
      this.$emit("submit", false);
    }
  }
}
</script>

<style lang="scss">
.confirmation-popup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20000;
  padding: $px16;
  width: $px496;

  border: $px1 solid $grey-8;
  border-top: 0;
  border-radius: 0 0 $px4 $px4;
  box-shadow: 0 $px4 $px8 0 $grey-7;

  background-color: $white;

  &__copy {
    margin-bottom: $px8;
  }

  &__actions {
    text-align: right;
  }
}
</style>
