import Vue from "vue/dist/vue.esm";
import VueI18n from "vue-i18n";
import twemoji from "twemoji";
import * as Sentry from "@sentry/vue";

Vue.use(VueI18n);

let md = require("markdown-it")({
  html: true,
  linkify: true,
  typographer: true,
  breaks: true
});
let markdownItAttrs = require('markdown-it-attrs');

let emoji = require("markdown-it-emoji");

md.use(emoji);

md.use(markdownItAttrs, {
  leftDelimiter: "{",
  rightDelimiter: "}",
  allowedAttributes: [] // empty array = all attributes are allowed
});

md.renderer.rules.emoji = function(token, idx) {
  return twemoji.parse(token[idx].content, {
    base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/'
  });
};

const stats = window.stats || {};

Vue.filter("md", value => {
  for (const key in stats) {
    value = value.replace(`%[${key}]`, stats[key]);
  }

  return md.renderInline(value);
});

Vue.filter("mdWithP", function (value) {
  for (const key in stats) {
    value = value.replace(`%[${key}]`, stats[key]);
  }

  return md.render(value);
});

Sentry.init({
  Vue,
  dsn: "https://61e9c335e4e74abfbf4ea7e560972ced@sentry.io/1519978",
  release: window.sqlhabitRelease,
  environment: window.sqlhabitEnv,
  beforeSend: function(event) {
    if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
      event.exception.values.forEach(error => {
        console.error(error);
      });

      return null;
    }
    return event;
  },
  tracesSampleRate: 0
});

Sentry.setUser({
  id: window.userSlug
})

export default Vue;
