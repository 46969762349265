import Vue from "modules/vue_twemoji";
import VueI18n from "vue-i18n";
import SubscriptionPricingPlans from "components/subscription-pricing-plans";
import PromoBanner from "components/promo-banner";
import axios from "axios";
import { setCouponCookie } from "controllers/promo-coupon-code";
import Sentry from "modules/sentry";
import { store } from "store";

Vue.use(VueI18n);

setCouponCookie();

const $container = document.querySelector(".js-subscription-pricing-plans");
const urlParams = new URLSearchParams(window.location.search);

if($container) {
  axios.get("/api/subscription-prices.json", {
    params: {
      pr: urlParams.get("ref")
    }
  }).then(response => {
    const subscriptionPlans = response.data.subscriptionPlans;

    new Vue({
      el: $container,
      i18n: new VueI18n({
        locale: "en"
      }),
      store,
      render: h => h(SubscriptionPricingPlans, {
        props: {
          subscriptionPlans
        }
      })
    });

    if(response.data.promo_banner) {
      const $container = document.querySelector(".vue-promo-banner-container");

      new Vue({
        el: $container,
        render: h => h(PromoBanner, {
          props: response.data.promo_banner
        })
      });
    }
  }).catch(function(error) {
    Sentry.captureException(error);
  });
}
