<template>
<div class="database-documentation">
  <div v-if="showDatasetMenu" class="h3 mb-16">
    {{ currentDatabase.name }}
  </div>

  <div class="flex mb-24">
    <div
      class="database-documentation__dataset-description font-small"
      v-if="currentDatabase.description"
      v-html="$options.filters.mdWithP(currentDatabase.description)" />

    <div class="database-documentation__schema-button">
      <SchemaVisualizerButton :databaseSlug="currentDatabase.slug" />
    </div>
  </div>

  <div class="database-documentation__search-container mb-24">
    <label class="font-label">Search</label>
    <TextInput
      cssClass="font-small"
      placeholder="Search by name, type or description"
      @input="updateSearchQuery" />
  </div>

  <div
    class="database-documentation__tables"
    v-if="currentDatabase.documentation">
    <div
      :key="table.name"
      class="database-documentation__table-container"
      :class="{
        'display-none': !showTable(table)
      }"
      v-for="table in currentDatabase.documentation">
      <div class="database-documentation__table-name-container mb-12 flex align-baseline">
        <TableIcon class="database-documentation__table-icon mr-8" />

        <div
          class="database-documentation__table-name weight-700"
          v-html="highlightSearchQuery(table.name)" />
      </div>

      <table class="info-table database-documentation__table">
        <thead>
          <th class="font-label">Column</th>
          <th class="font-label">Type</th>
          <th class="font-label">Description</th>
        </thead>
        <tbody>
          <tr
            :key="column.name"
            v-for="column in table.columns"
            :class="{
              'display-none': !showColumn(table.name, column)
            }">
            <td
              class="font-small"
              v-html="highlightSearchQuery(column.name)" />
            <td
              class="font-small"
              v-html="highlightSearchQuery(column.type)" />
            <td
              class="font-small width-100"
              v-html="$options.filters.md(highlightSearchQuery(column.description))" />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import TextInput from "components/shared/text-input";
import TableIcon from "@images/font_awesome/table-light.svg";
import SchemaVisualizerButton from "components/schema-visualizer-button";
import { notify } from "modules/notify";

export default {
  components: {
    TextInput,
    TableIcon,
    SchemaVisualizerButton
  },
  computed: {
    datasetId() {
      return this.windowState.datasetId || this.forceDatasetId;
    },
    databasesInputOptions() {
      return this.databases.map(d => {
        return [
          d.name,
          d.id,
          "font_awesome/database-light.svg"
        ];
      });
    },
    currentDatabase() {
      if(this.databases.length === 0) {
        return {};
      }

      return this.databases.find(d => d.id === this.datasetId);
    }
  },
  data() {
    return {
      databases: [],
      searchQuery: null,
      windowState: window.state
    }
  },
  props: {
    showDatasetMenu: {
      type: Boolean,
      default: false
    },
    forceDatasetId: {
      type: Number,
      default: null
    }
  },
  methods: {
    fetchDocumentation() {
      axios.get("/api/datasets.json", {}, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        this.databases = response.data.datasets;
      }).catch((error) => {
        notify({
          message: "Can't load database documentation. Please, refresh the page or contact support at support@sqlhabit.com",
          type: "error"
        });
      });
    },
    updateDatabase(datasetId) {
      window.state.datasetId = datasetId;
    },
    updateSearchQuery(newSearchQuery) {
      this.searchQuery = newSearchQuery;
    },
    highlightSearchQuery(copy) {
      if(this.searchQuery && copy.includes(this.searchQuery)) {
        return copy.replace(this.searchQuery, `<mark>${this.searchQuery}</mark>`);
      } else {
        return copy;
      }
    },
    showColumn(tableName, column) {
      if(!this.searchQuery || this.searchQuery.length === 0) {
        return true;
      }

      // If user searched for a table – let's show all columns #userfeedback
      if(tableName.toLowerCase().includes(this.searchQuery)) {
        return true;
      }

      return column.name.toLowerCase().includes(this.searchQuery) ||
        column.type.toLowerCase().includes(this.searchQuery) ||
        column.description.toLowerCase().includes(this.searchQuery);
    },
    showTable(table) {
      if(this.searchQuery && this.searchQuery.length > 0) {
        return table.name.toLowerCase().includes(this.searchQuery) ||
          table.columns.some(column => this.showColumn(table.name, column));
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.fetchDocumentation();
  }
}
</script>

<style lang="scss">
.database-documentation {
  max-width: $px720;

  &__dataset-description {
    @include breakpoint($md) {
      padding-right: $px24;
    }
  }

  &__schema-button {
    display: none;

    @include breakpoint($md) {
      display: block;
    }
  }

  &__table-container {
    &:not(:last-child) {
      margin-bottom: $px32;
    }
  }

  &__table {
    tr {
      &:nth-child(odd) {
        background-color: $white;
      }

      &:nth-child(even) {
        background-color: $grey-10;
      }
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__table-name {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
  }

  &__table-icon {
    height: 12px;

    color: $grey-3;
  }
}
</style>
