<template>
  <div class="query-error">
    {{ errors }}
  </div>
</template>

<script>
export default {
  props: ["errors"]
}
</script>

<style>
.query-error {
  padding: 10px;

  border: 5px solid red;
}
</style>
