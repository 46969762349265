import Vue from "modules/vue_twemoji";
import VueI18n from "vue-i18n";
import { getCookie } from "modules/cookie";
import { checkIn } from "modules/pageview-analytics";
import { setupAbtests } from "modules/abtest-categorization";
import CookieConsent from "components/cookie-consent";
import { loadGTM } from "modules/gtm";

Vue.use(VueI18n);

const COOKIE_NAME = "cookie-consent";
const currentAbTest = setupAbtests();

checkIn(currentAbTest);

if(getCookie(COOKIE_NAME)) {
  if(getCookie(COOKIE_NAME) === "true") {
    loadGTM();
  }
} else {
  const $container = document.querySelector(".js-cookie-consent");

  new Vue({
    i18n: new VueI18n({
      locale: "en"
    }),
    el: $container,
    render: h => h(CookieConsent)
  });
}
