import axios from "axios";
import { mountCountdown } from "controllers/countdown";
import { store } from "store";
import { toggleLoggedInElements, toggleLoggedOutElements, toggleCustomerElements } from "modules/toggle-auth-dependent-elemenets";

const loadDesktopNavbarPartial = ($desktopNavbar) => {
  const params = {
    sticky_header_enabled: $desktopNavbar.dataset.stickyHeaderEnabled,
    show_pricing_link: $desktopNavbar.dataset.showPricingLink,
    hide_skill_test_link: $desktopNavbar.dataset.hideSkillTestLink,
    hide_login_menu: $desktopNavbar.dataset.hideLoginMenu,
    hide_signup_button: $desktopNavbar.dataset.hideSignupButton,
    hide_buy_button: $desktopNavbar.dataset.hideBuyButton,
    container_class: $desktopNavbar.dataset.containerClass
  };

  axios.get("/api/partials/desktop-navbar.json", {
    params: params
  }).then(response => {
    const params = response.data;

    if(params.partial) {
      $desktopNavbar.outerHTML = params.partial;
    }

    mountCountdown();

    const isGuest = store.getters["user/isGuest"];
    const segment = store.getters["user/segment"];

    toggleLoggedInElements(isGuest);
    toggleLoggedOutElements(isGuest);
    toggleCustomerElements(segment);
  });
}

export default loadDesktopNavbarPartial;
