<template>
<div class="sql-reference">
  <div
    class="sql-reference__dataset-description font-small mb-16"
    v-html="$options.filters.mdWithP($t('postgresDescription'))" />

  <div class="sql-reference__search-container mb-24">
    <label class="font-label">Search</label>
    <TextInput
      cssClass="font-small"
      placeholder="Search by name, type or description"
      @input="updateSearchQuery" />
  </div>

  <div
    class="sql-reference__tables"
    ref="referenceTables"
    v-if="sqlReference">
    <div
      :key="section.name"
      class="sql-reference__table-container"
      :class="{
        'display-none': !showTable(section.name, section.keywords)
      }"
      v-for="section in sqlReference">
      <div class="sql-reference__table-name-container mb-12 flex align-baseline">
        <div
          class="sql-reference__table-name weight-700"
          v-html="highlightSearchQuery(section.name)" />
      </div>

      <table class="info-table sql-reference__table">
        <thead>
          <th class="font-label">Column</th>
          <th class="font-label">Description</th>
        </thead>
        <tbody>
          <tr
            :key="keyword.name"
            v-for="keyword in section.keywords"
            :class="{
              'display-none': !showColumn(keyword)
            }">
            <td
              class="font-small"
              v-html="highlightSearchQuery(keyword.name)" />
            <td
              class="font-small width-100"
              v-html="$options.filters.mdWithP(highlightSearchQuery(keyword.description))" />
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div v-else>
    <span
      class="font-small"
      v-html="$options.filters.mdWithP(`Loading... :hourglass_flowing_sand:`)" />
  </div>
</div>
</template>

<script>
import axios from "axios";
import TextInput from "components/shared/text-input";
import TableIcon from "@images/font_awesome/table-light.svg";
import { highlightChildren } from "modules/code-highlight";
import debounce from "lodash.debounce";
import { notify } from "modules/notify";

export default {
  components: {
    TextInput,
    TableIcon
  },
  data() {
    return {
      searchQuery: null,
      sqlReference: []
    }
  },
  watch: {
    searchQuery: debounce(function() {
      this.$nextTick(() => {
        highlightChildren(this.$refs.referenceTables);
      });
    })
  },
  methods: {
    updateSearchQuery(newSearchQuery) {
      this.searchQuery = newSearchQuery;
    },
    highlightSearchQuery(copy) {
      if(this.searchQuery && this.containsSearchQuery(copy, this.searchQuery)) {
        const searchMask = this.searchQuery;
        const regEx = new RegExp(searchMask, "ig");

        // Replacing with a function to ensure lower/upper case replacing
        return copy.replace(regEx, (matchedSubstring, offset, string) => {
          const prefix = string.substring(0, offset);

          // Detect if we're inside inline code statement ``
          const countInlineCodes = prefix.split("`").length - 1;
          if(countInlineCodes % 2 === 1) {
            return matchedSubstring;
          }

          // Detect if we're inside code block ~~~pgsql ... ~~~
          const startOfMarkdownCode = prefix.lastIndexOf("~~~pgsql\n");
          const endOfMarkdownCode = prefix.lastIndexOf("~~~\n");
          if(startOfMarkdownCode && (!endOfMarkdownCode || startOfMarkdownCode > endOfMarkdownCode)) {
            return matchedSubstring;
          }

          return `<mark>${matchedSubstring}</mark>`;
        });
      } else {
        return copy;
      }
    },
    containsSearchQuery(input, query) {
      return input.toLowerCase().replace(/~~~pgsql[\W\d\w]+~~~/, "").includes(query.toLowerCase());
    },
    showColumn(column) {
      if(this.searchQuery && this.searchQuery.length > 0) {
        return this.containsSearchQuery(column.name, this.searchQuery) ||
          this.containsSearchQuery(column.description, this.searchQuery);
      } else {
        return true;
      }
    },
    showTable(section, keywords) {
      if(this.searchQuery && this.searchQuery.length > 0) {
        return this.containsSearchQuery(section, this.searchQuery) ||
          keywords.some(keyword => this.showColumn(keyword));
      } else {
        return true;
      }
    },
    fetchSqlReference() {
      axios.get("/api/sql-reference.json", {}, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        this.sqlReference = response.data.sql_reference;

        this.$nextTick(() => {
          highlightChildren(this.$refs.referenceTables);
        });
      }).catch((error) => {
        notify({
          message: error.response.data.errors,
          type: "error"
        });
      });
    },
  },
  mounted() {
    if(this.sqlReference.length > 0) {
      this.$nextTick(() => {
        highlightChildren(this.$refs.referenceTables);
      });
    } else {
      this.fetchSqlReference();
    }
  },
  i18n: {
    messages: {
      en: {
        postgresDescription: `All databases on SQL Habit are built with <a target="_blank" href="https://www.postgresql.org/docs/11/sql-syntax.html">PostgreSQL</a>.`
      }
    }
  }
}
</script>

<style lang="scss">
.sql-reference {
  max-width: $px720;

  &__table-container {
    &:not(:last-child) {
      margin-bottom: $px32;
    }
  }

  &__table {
    tr {
      &:nth-child(odd) {
        background-color: $white;
      }

      &:nth-child(even) {
        background-color: $grey-10;
      }
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__table-name {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
  }

  &__close-icon {
    width: $px24;
    height: $px24;
    position: absolute;
    top: $px24;
    right: $px24;

    &:hover {
      color: $grey-6;
    }
  }

  &__table-icon {
    height: 12px;

    color: $grey-3;
  }
}
</style>
