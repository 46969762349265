<template>
  <input
    class="text-input font-small"
    :class="cssClass"
    type="text"
    v-model="value">
</template>

<script>
export default {
  name: "TextInput",
  data() {
    return {
      value: null
    };
  },
  props: {
    cssClass: {
      type: String,
      default: "font-regular"
    }
  },
  watch: {
    value(newVal) {
      this.$emit("input", newVal);
    }
  }
}
</script>
