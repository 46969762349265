import Vue from "modules/vue_twemoji";
import SelectedTextMenu from "components/selected-text-menu";
import EmailForm from "components/selected-text-email-form";
import AiAssistantForm from "components/selected-text-ai-assistant-form";
import { store } from "store";

let menuVm;

export const initSelectedTextMenu = () => {
  const $selectableTextArea = document.querySelector(".js-with-selected-text-menu");

  if (!$selectableTextArea) {
    return;
  }

  const $menuContainer = document.querySelector(".selected-text-menu-container");

  function closeMenu() {
    if(menuVm) {
      menuVm.$refs.selectedTextMenu.isVisible = false;
      menuVm.$refs.selectedTextMenu.textFragment = "";
    }
  }

  function handleMouseUp(event) {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if(selectedText.length > 0) {
      const range = selection.getRangeAt(0);

      if (!$selectableTextArea.contains(range.commonAncestorContainer)) {
        return;
      }

      const rect = range.getBoundingClientRect();
      const y = rect.y + window.scrollY + rect.height / 2;

      const tabBody = document.querySelector(".js-content-container");
      const relativeY = y - tabBody.offsetTop;

      $menuContainer.style.left = `0px`;
      $menuContainer.style.top = `${relativeY}px`;

      const $menuFormsContainer = document.querySelector(".selected-text-menu-forms");
      $menuFormsContainer.style.left = `0px`;
      $menuFormsContainer.style.top = `${relativeY}px`;

      mountSelectedTextMenu({ selectedText });
    }
  }

  function handleClick(event) {
    setTimeout(() => {
      const selection = window.getSelection();
      if (selection.toString().trim().length > 0) {
        return;
      } else if (selection?.isCollapsed) {
        closeMenu();
        return;
      }

      const $activeMenu = document.querySelector(".selected-text-menu--active");

      if (!$activeMenu) {
        return;
      }

      if($activeMenu &&
        (event.target.closest(".selected-text-menu--active") ||
        event.target.closest(".selected-text-menu-container") ||
        event.target.closest(".selected-text-menu-forms"))) {

        return;
      }

      closeMenu();
    }, 0);
  }

  function mountEmailForm({ selectedText }) {
    const $emailFormContainer = document.createElement("div");
    $emailFormContainer.classList.add("email-form-container");
    document.querySelector(".js-selected-text-menu-forms").appendChild($emailFormContainer);

    new Vue({
      el: $emailFormContainer,
      name: "SelectedTextEmailFormContainer",
      components: {
        EmailForm
      },
      store,
      data() {
        return {
          selectedText: selectedText
        };
      },
      template: `
        <EmailForm
          :selectedText="selectedText"
          @close="close" />
      `,
      methods: {
        close() {
          closeMenu();

          this.$destroy();

          this.$el.parentNode.removeChild(this.$el);
        }
      }
    });
  }

  function mountAiAssistantForm({ selectedText }) {
    const $emailFormContainer = document.createElement("div");
    $emailFormContainer.classList.add("email-form-container");
    document.querySelector(".js-selected-text-menu-forms").appendChild($emailFormContainer);

    new Vue({
      el: $emailFormContainer,
      name: "AiAssistantFormContainer",
      components: {
        AiAssistantForm
      },
      store,
      data() {
        return {
          selectedText: selectedText
        };
      },
      template: `
        <AiAssistantForm
          :selectedText="selectedText"
          @close="close" />
      `,
      methods: {
        close() {
          closeMenu();

          this.$destroy();

          this.$el.parentNode.removeChild(this.$el);
        }
      }
    });
  }

  function mountSelectedTextMenu({ selectedText }) {
    if(!menuVm) {
      menuVm = new Vue({
        el: ".js-selected-text-menu",
        name: "SelectedTextMenuContainer",
        components: {
          SelectedTextMenu
        },
        data() {
          return {
            visible: true,
            activeForm: null,
            textFragment: selectedText
          };
        },
        methods: {
          setTextFragment(textFragment) {
            this.textFragment = textFragment;
          },
          selectForm(form) {
            this.activeForm = form;

            if (form === "email") {
              mountEmailForm({ selectedText: this.textFragment });
            } else if (form === "ai-assistant") {
              mountAiAssistantForm({ selectedText: this.textFragment });
            }

            closeMenu();
          }
        },
        template: `
          <SelectedTextMenu
            ref="selectedTextMenu"
            @selectForm="selectForm" />
        `
      });
    }

    menuVm.$refs.selectedTextMenu.isVisible = true;
    menuVm.setTextFragment(selectedText);
  }

  document.addEventListener("mouseup", handleMouseUp);
  document.addEventListener("click", handleClick);

  document.addEventListener("keydown", function(e) {
    if(e.key === "Escape" || e.key === "Esc") {
      closeMenu();
    }
  });
}
