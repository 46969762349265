<template>
  <div class="sql-level-step pt-32">
    <div class="sql-level-step__container">
      <div class="sql-level-step__headline mb-24">
        <div class="flex align-center">
          <img
            class="occupation-step__avatar w-48 h-48 radius-50 mr-8"
            :src="require('@images/avatars/avatar.png')"
            alt="Anatoli, the author of SQL Habit" />

          <span
            class="h2"
            v-html="$options.filters.md('How familiar are you with SQL?')" />
        </div>
      </div>

      <div class="sql-level-step__levels">
        <SelectableCard
          v-for="level in availableLevels"
          class="sql-level-step__level"
          v-bind:key="level"
          @selected="toggleLevel(level)"
          :value="level"
          :selected="selectedLevel === level"
          :emoji="$t(`levelEmoji.${level}`)"
          :name="$t(`levelName.${level}`)"
          :description="$t(`levelDescription.${level}`)" />
      </div>
    </div>
  </div>
</template>

<script>
import SelectableCard from "components/shared/selectable-card";

export default {
  components: {
    SelectableCard
  },
  data() {
    return {
      availableLevels: ["beginner", "intermediate", "advanced"],
      selectedLevel: null
    }
  },
  props: ["sqlLevel"],
  methods: {
    toggleLevel(level) {
      this.selectedLevel = level;

      this.$emit("toggle-sql-level", this.selectedLevel);
    },
  },
  mounted() {
    this.selectedLevel = this.sqlLevel;
  },
  i18n: {
    messages: {
      en: {
        levelEmoji: {
          beginner: ":seedling:",
          intermediate: ":wrench:",
          advanced: ":muscle:"
        },
        levelName: {
          beginner: "**GETTING STARTED**",
          intermediate: "**ON MY WAY**",
          advanced: "**FLUENT SQL**"
        },
        levelDescription: {
          beginner: "I'm just starting with SQL.",
          intermediate: "I can explain INNER and LEFT JOIN.",
          advanced: "I can easily manipulate window functions."
        }
      }
    }
  }
}
</script>

.<style lang="scss">
.sql-level-step {
  &__levels {
    display: flex;
    flex-direction: column;
    max-width: $px720;
    gap: $px16;

    @include breakpoint($sm) {
      width: 100%;
      flex-direction: row;
    }
  }
}
</style>
