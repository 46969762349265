export const bemClass = ({ baseClass, modifiers }) => {
  if (!modifiers) {
    return baseClass;
  }

  let classes = [baseClass];

  classes = classes.concat(modifiers.map(m => `${baseClass}--${m}`));

  return classes.join(" ");
}
