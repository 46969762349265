<template>
  <div class="onboarding">
    <div class="onboarding__progress-bar">
      <div
        v-for="(stepName, index) in steps"
        :key="index"
        :style="{ width: `${100 / totalSteps}%` }"
        class="onboarding__progress-bar-step"
        :class="{
          'onboarding__progress-bar-step--active': step >= index,
          'onboarding__progress-bar-step--clickable': step > index
        }"
        @click.prevent="handleProgressBarClick(index)" />
    </div>

    <header class="onboarding__header">
      <Logo class="onboarding__header-logo" />

      <div class="onboarding__steps-counter">
        Step {{ currentStep }} / {{ totalSteps }}
      </div>
    </header>

    <div class="onboarding__container container">
      <div class="onboarding_step">
        <transition name="slide-fade">
          <FirstNameStep
            @toggle-first-name="updateFirstName"
            :firstName="firstName"
            v-if="steps[step] === 'firstNameStep'" />
        </transition>

        <transition name="slide-fade">
          <OccupationStep
            :occupations="occupations"
            :otherOccupation="otherOccupation"
            @toggle-occupation="updateOccupations"
            @toggle-other-occupation="updateOtherOccupation"
            v-if="steps[step] === 'occupationStep'" />
        </transition>

        <transition name="slide-fade">
          <DataLevelStep
            :dataLevel="dataLevel"
            @toggle-data-level="updateDataLevel"
            v-if="steps[step] === 'dataLevelStep'" />
        </transition>

        <transition name="slide-fade">
          <SqlLevelStep
            :sqlLevel="sqlLevel"
            @toggle-sql-level="updateSqlLevel"
            v-if="steps[step] === 'sqlLevelStep'" />
        </transition>

        <transition name="slide-fade">
          <SetGoalStep
            :goals="goals"
            :otherGoal="otherGoal"
            @toggle-set-goals="updateGoals"
            @toggle-other-goal="updateOtherGoal"
            v-if="steps[step] === 'setGoalsStep'" />
        </transition>

        <transition name="slide-fade">
          <IntroductionStep
            v-if="steps[step] === 'introductionStep'" />
        </transition>

        <transition name="slide-fade">
          <ChooseSubscriptionPlan
            @skip="nextPage"
            v-if="steps[step] === 'chooseSubscriptionPlan'" />
        </transition>
      </div>

      <div
        v-if="showActions"
        class="onboarding__actions">
        <div class="onboarding__actions-container">
          <a
            class="onboarding__skip-step"
            v-if="skipAvailable"
            href="#"
            v-on:click.prevent="nextPage">
            Skip
          </a>
          <a
            href="#"
            class="onboarding__next-cta btn btn--fat btn--wide btn--strong btn--full-width-xs"
            v-on:click.prevent="nextPage">
            {{ ctaCopy }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FirstNameStep from "components/onboarding/first-name-step";
import OccupationStep from "components/onboarding/occupation-step";
import DataLevelStep from "components/onboarding/data-level-step";
import SqlLevelStep from "components/onboarding/sql-level-step";
import SetGoalStep from "components/onboarding/set-goal-step";
import IntroductionStep from "components/onboarding/introduction-step";
import ChooseSubscriptionPlan from "components/onboarding/choose-subscription-step";
import Logo from "@images/svgs/header_logo.svg";
import Sentry from "modules/sentry";

export default {
  components: {
    FirstNameStep,
    OccupationStep,
    DataLevelStep,
    SqlLevelStep,
    IntroductionStep,
    SetGoalStep,
    Logo,
    ChooseSubscriptionPlan
  },
  data() {
    return {
      firstName: null,
      otherOccupation: null,
      occupations: [],
      dataLevel: null,
      sqlLevel: null,
      goals: [],
      otherGoal: null,
      step: 0
    }
  },
  watch: {
    async step(_, prevStepIndex) {
      if (this.steps[prevStepIndex] === "firstNameStep") {
        await this.upsertFirstName();
      }
    }
  },
  computed: {
    showActions() {
      return this.steps[this.step] !== "chooseSubscriptionPlan";
    },
    ctaCopy() {
      return this.step === this.steps.length - 1 ? "LET'S GO 🚀" : "CONTINUE";
    },
    currentStep() {
      return this.step + 1;
    },
    totalSteps() {
      return this.steps.length;
    },
    skipAvailable() {
      return false;
    },
    steps() {
      let steps = [
        "firstNameStep",
        "occupationStep",
        "dataLevelStep",
        "sqlLevelStep",
        "setGoalsStep",
        "chooseSubscriptionPlan"
      ];

      return steps;
    }
  },
  methods: {
    async upsertFirstName() {
      let formData = new FormData();

      formData.append("first_name", this.firstName);

      try {
        await axios.patch("/api/users/update_first_name", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
          }
        })
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    updateFirstName(firstName) {
      this.firstName = firstName;
    },
    updateOccupations(occupations) {
      this.occupations = occupations;
    },
    updateOtherOccupation(otherAreaInput) {
      this.otherOccupation = otherAreaInput;
    },
    updateDataLevel(dataLevel) {
      this.dataLevel = dataLevel;
    },
    updateSqlLevel(sqlLevel) {
      this.sqlLevel = sqlLevel;
    },
    updateGoals(goals) {
      this.goals = goals;
    },
    updateOtherGoal(otherGoalInput) {
      this.otherGoal = otherGoalInput;
    },
    selectedValues(object) {
      let result = [];

      Object.entries(object).forEach(([key, value]) => {
        if(value) {
          result.push(key);
        }
      });

      return result;
    },
    nextPage() {
      if(this.steps.length === this.step + 1) {
        let payload = {};
        payload.firstName = this.firstName;

        payload.occupations = this.selectedValues(this.occupations);

        if(this.otherOccupation) {
          payload.occupations.push(this.otherOccupation);
        }

        payload.goals = this.selectedValues(this.goals);

        if(this.otherGoal) {
          payload.goals.push(this.otherGoal);
        }

        payload.data_level = this.dataLevel;
        payload.sql_level = this.sqlLevel;

        let formData = new FormData();
        formData.append(
          "onboarding[payload]",
          JSON.stringify(payload)
        );

        axios.post("/api/onboardings", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
          }
        }).then(response => {
        }).catch(function(error) {
          Sentry.captureException(error);
        }).finally(() => {
          window.location = "/contents";
        })
      } else {
        this.step += 1;
      }
    },
    handleProgressBarClick(index) {
      if(this.step > index) {
        this.step = index;
      }
    }
  }
}
</script>

<style lang="scss">
.onboarding {
  position: relative;

  &__container {
    position: relative;
    padding-bottom: $px64;

    @include breakpoint($sm) {
      padding-bottom: 0;
    }

    @include breakpoint($md) {
      padding-top: $px32;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: $px24 $px24;
  }

  &__actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $px16 0;

    text-align: right;

    @include breakpoint($sm) {
      padding: $px24;

      background-color: $grey-10
    }
  }

  &__actions-container {
    @include container;
  }

  &__skip-step {
    margin-right: $px16;

    @include font-small;
  }

  &__next-cta {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    border-radius: 0;

    @include breakpoint($sm) {
      position: relative;
      left: auto;
      bottom: auto;
      width: auto;

      border-radius: $px4;
    }
  }

  &__steps-counter {
    font-weight: 600;
  }

  &__progress-bar {
    display: flex;
    justify-content: space-between;
    gap: $px8;
    width: 100%;
  }

  &__progress-bar-step {
    height: $px8;

    background-color: $grey-8;

    transition: background-color .4s;

    &--active {
      background-color: $blue-8;
    }

    &--clickable {
      cursor: pointer;

      &:hover {
        background-color: $blue-7;
      }
    }
  }

  // Steps animation
  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    display: none;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
}
</style>
