<template>
<div class="page-feedback-hover-menu">
  <div
    class="page-feedback-hover-menu__option page-feedback-hover-menu__email"
    @click.prevent="$emit('openEmailForm')">
    <div class="page-feedback-hover-menu__icon-wrapper mr-16">
      <EnvelopeIcon class="page-feedback-hover-menu__icon page-feedback-hover-menu__icon--email" />
    </div>

    <div class="page-feedback-hover-menu__body">
      <div class="page-feedback-hover-menu__title mb-4">
        Send Feedback
      </div>

      <div class="page-feedback-hover-menu__description">
        Suggest new features, content, report bugs, or just say Hi.
      </div>
    </div>
  </div>

  <a
    :href="aiCoachPath"
    class="page-feedback-hover-menu__option page-feedback-hover-menu__ai-coach"
    :class="{
      'js-open-signup-popup': isGuest
    }">
    <div class="page-feedback-hover-menu__icon-wrapper mr-16">
      <RobotIcon class="page-feedback-hover-menu__icon" />
    </div>

    <div class="page-feedback-hover-menu__body">
      <div class="page-feedback-hover-menu__title mb-4">
        {{ aiHeadline }}
      </div>

      <div class="page-feedback-hover-menu__description">
        Dig deeper into SQL Habit lessons and exercises, write and run queries, get content recommendations, and more.
      </div>
    </div>
  </a>
</div>
</template>

<script>
import EnvelopeIcon from "@images/font_awesome/envelope-light.svg";
import RobotIcon from "@images/font_awesome/robot-light.svg";
import { mapGetters } from "vuex";

export default {
  name: "PageFeedbackHoverMenu",
  components: {
    EnvelopeIcon,
    RobotIcon
  },
  data() {
    return {
      contentItemPresent: !!window.state?.contentItem
    };
  },
  computed: {
    ...mapGetters("user", ["isGuest", "loggedIn"]),
    aiCoachPath() {
      let path = "/ai-coach";

      if (window.state?.contentItem) {
        const { contentId, contentType } = window.state.contentItem;

        path += `?content_id=${contentId}&content_type=${contentType}`;
      }

      return path;
    },
    aiHeadline() {
      if (this.contentItemPresent) {
        return "Discuss with AI Coach";
      } else {
        return "Talk to AI Coach";
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.page-feedback-hover-menu {
  width: 100%;

  box-shadow: 0 $px4 $px16 0 $grey-8;

  border-radius: $px8;
  border: 1px solid $grey-8;

  background-color: $grey-10;

  @include breakpoint($sm) {
    width: auto;
    max-width: $px496;
  }

  &__option {
    display: flex;
    align-items: center;
    padding: $px16;

    text-decoration: none;

    cursor: pointer;

    &:first-child {
      border-radius: $px8 $px8 0 0;
    }

    &:last-child {
      border-radius: 0 0 $px8 $px8;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey-8;
    }

    &:hover {
      opacity: 1;

      background-color: $grey-9;
    }
  }

  &__icon-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border-radius: 50%;

    background-color: $grey-8;
  }

  &__icon {
    width: $px24;
    height: $px24;
    flex-shrink: 0;

    &--email {
      width: $px20;
      height: $px20;
    }
  }

  &__title {
    font-weight: 700;
  }

  &__description {
    @include font-small;
  }
}
</style>
