import Vue from "vue/dist/vue.esm";
import PageFeedback from "components/page-feedback";
import { store } from "store";

const $pageFeedbackContainer = document.querySelector(".js-page-feedback");

if($pageFeedbackContainer) {
  const size = $pageFeedbackContainer.dataset.size || "normal";

  new Vue({
    el: $pageFeedbackContainer,
    store,
    render: h => h(PageFeedback, {
      props: {
        size: size
      }
    })
  });
}
