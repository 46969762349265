<template>
  <a
    class="schema-visualizer-button flex align-center w-144 h-144"
    target="_blank"
    :href="`https://sqlhabit.github.io/sql_schema_visualizer/databases/${databaseSlug}`">
    <div class="schema-visualizer-button__copy">Schema visualization</div>

    <img
      class="schema-visualizer-button__snapshot"
      :src="require('@images/database_documentation/schema_visualizer@2x.jpg')"
      :srcset="require('@images/database_documentation/schema_visualizer.jpg') + ' 1x, ' + require('@images/database_documentation/schema_visualizer@2x.jpg') + ' 2x'" />
  </a>
</template>

<script>
export default {
  name: "SchemaVisualizerButton",
  props: {
    databaseSlug: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
.schema-visualizer-button {
  $root: &;

  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  border: $px1 solid $grey-9;
  border-radius: $px4;

  text-decoration: none;
  text-align: center;

  &__copy {
    @include font-regular;
    font-weight: 600;
  }

  &__snapshot {
    position: absolute;
    top: -$px8;
    left: -$px32;
    width: $px192;
    z-index: -1;
    opacity: 0.6;

    transform: rotate(-13deg);
  }

  &:hover {
    opacity: 1;

    border-color: $grey-8;

    #{$root}__copy {
      color: $grey-1;
    }

    #{$root}__snapshot {
      transform: scale(1.05) rotate(-13deg);
    }
  }
}
</style>
