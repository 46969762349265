const insertMatch = (rowDelta = 1, columnDelta = -1) => {
  return (editor, data) => {
    editor.completer.insertMatch({ value: data.value });

    let currentCursorPosition = editor.getCursorPosition()

    let newRow = currentCursorPosition.row + rowDelta;
    let newColumn = currentCursorPosition.column + columnDelta;

    if(rowDelta === -1) {
      const prevRowLength = editor.session.getLine(currentCursorPosition.row - 1).length;

      // WILD: Inside gotoLine(function) row number starts from 1
      // but getCursorPosition() returns row index which starts from 0 (!!)
      newRow = currentCursorPosition.row;
      newColumn = prevRowLength + columnDelta;
    }

    editor.gotoLine(newRow, newColumn);
  };
}

const PGSQL_KEYWORDS = {
  "AND": "operator",
  "OR": "operator",
  "NOT": "operator",
  "BETWEEN": "operator",
  "IN": "operator",
  "AS": "keyword",
  "ASC": "keyword",
  "DESC": "keyword",
  "CASE": "keyword",
  "DISTINCT": "keyword",
  "FROM": "keyword",
  "GROUP BY": "keyword",
  "HAVING": "keyword",
  "JOIN": "keyword",
  "INNER JOIN": "keyword",
  "LEFT JOIN": "keyword",
  "IS NULL": "operator",
  "IS NOT NULL": "operator",
  "LIKE": "function",
  "LIMIT": "keyword",
  "FROM": "keyword",
  "ORDER BY": "operator",
  "UNION":"operator",
  "UNION ALL": "operator",
  "WHERE": "keyword",
  "SELECT": "keyword",
  "NOT NULL": "operator",
  "WITH..AS": {
    type: "keyword",
    caption: "WITH..AS",
    value: "WITH  AS (\n)",
    completer: {
      insertMatch: insertMatch(-1, -5)
    }
  },
  "SUM": {
    type: "aggregate function",
    value: "SUM()",
    completer: {
      insertMatch: insertMatch()
    }
  },
  "MIN": {
    type: "aggregate function",
    value: "MIN()",
    completer: {
      insertMatch: insertMatch()
    }
  },
  "MAX": {
    type: "aggregate function",
    value: "MAX()",
    completer: {
      insertMatch: insertMatch()
    }
  },
  "AVG": {
    type: "aggregate function",
    value: "AVG()",
    completer: {
      insertMatch: insertMatch()
    }
  },
  "MEDIAN": {
    type: "aggregate function",
    value: "MEDIAN()",
    completer: {
      insertMatch: insertMatch()
    }
  },
  "COUNT": {
    type: "aggregate function",
    value: "COUNT()",
    completer: {
      insertMatch: insertMatch()
    }
  }
};

export default PGSQL_KEYWORDS;
