<template>
<div class="team-subscription-size-calculator">
  <div
    v-if="showOriginalPrice & teamSize > 0"
    class="team-subscription-size-calculator__savings">
    SAVE {{ savingsPercentage }}%
  </div>

  <div class="team-subscription-size-calculator__headline mb-32">
    TEAM PLAN
  </div>

  <div class="team-subscription-size-calculator__prices mb-32">
    <div
      v-if="showOriginalPrice"
      class="team-subscription-size-calculator__original-price mr-8">
      {{ originalPriceFormatted }}
    </div>

    <div class="team-subscription-size-calculator__final-price mr-8">
      {{ finalPriceFormatteed }}
    </div>

    <div class="team-subscription-size-calculator__per-year flex">
      /{{ $t(`perBillingPeriod.${subscriptionPlan.billingPeriod}`) }}
    </div>
  </div>

  <div
    class="team-subscription-size-calculator__plan-description mb-32"
    v-html="$options.filters.md($t('planDescription', { minDiscountableTeamSize }))">
  </div>

  <div class="team-subscription-size-calculator__form">
    <div class="mb-16">
      <label
        class="team-subscription-size-calculator__size-label">
        Team size
      </label>
      <input
        class="team-subscription-size-calculator__size-input"
        type="number"
        name="team_size"
        placeholder="42"
        v-model.number="teamSize"
        min="1"
        @input="updateTeamSize">
    </div>

    <div>
      <a
        :href="purchaseHref"
        class="btn btn--fat btn--full-width mb-8"
        :class="{
          'js-open-signup-popup': isGuest,
          'btn--disabled': teamSize < 1
        }"
        :data-after-auth="purchaseHref">
        {{ ctaCopy }}
      </a>
    </div>

    <div
      class="team-subscription-size-calculator__billing-price font-small">
      <span
        v-if="showOriginalPrice"
        class="mr-4 team-subscription-size-calculator__billing-price--striked">
        {{ originalPriceFormatted }}
      </span>
      {{ finalPriceFormatteed }} billed {{ $t(`billingPeriod.${subscriptionPlan.billingPeriod}`) }}
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import currency from "currency.js";

const MIN_TEAM_SIZE = 1;

const currencyFormatter = {
  usd: (value) => {
    return currency(value, {
      symbol: "$",
      precision: 2,
      fromCents: true,
      separator: ",",
      decimal: ".",
      pattern: "!#"
    }).format().replace(".00", "");
  },
  eur: (value) => {
    return currency(value, {
      symbol: "€",
      precision: 2,
      fromCents: true,
      separator: ".",
      decimal: ",",
      pattern: "#!"
    }).format().replace(",00", "");
  }
}

const calculateTieredPrice = (quantity, tiers) => {
  let totalPrice = 0;
  let remainingQuantity = quantity;

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];
    const maxQuantity = tier.up_to === "inf" ? Infinity : tier.up_to;
    const unitAmount = tier.unit_amount;

    // Calculate the number of units in this tier
    let unitsInTier;
    if (i === 0) {
      unitsInTier = Math.min(maxQuantity, remainingQuantity);
    } else {
      const previousMax = tiers[i - 1].up_to;
      unitsInTier = Math.min(maxQuantity - previousMax, remainingQuantity);
    }

    // Calculate the price for this tier
    totalPrice += unitsInTier * unitAmount;

    // Reduce the remaining quantity
    remainingQuantity -= unitsInTier;

    // Stop processing if there's no remaining quantity
    if (remainingQuantity <= 0) {
      break;
    }
  }

  return totalPrice;
}

export default {
  methods: {
    updateTeamSize(event) {
      if(parseInt(event.target.value) < MIN_TEAM_SIZE) {
        return;
      }
    }
  },
  watch: {
    teamSize(oldValue, newValue) {
      if (typeof newValue !== "number") {
        this.teamSize = 1
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isGuest"]),
    ctaCopy() {
      if(this.teamSize === 1) {
        return `Buy ${this.teamSize} subscription seat`;
      } else {
        return `Buy ${this.teamSize} subscription seats`;
      }
    },
    savingsPercentage() {
      if (this.showOriginalPrice) {
        return Math.round(100.0 * (this.originalPrice - this.finalPrice) / this.originalPrice);
      } else {
        return 0;
      }
    },
    purchaseHref() {
      return `/subscription-purchases/new?slug=${this.subscriptionPlan.slug}&quantity=${this.teamSize}`;
    },
    originalPrice() {
      return this.subscriptionPlan.originalUnitPrice * this.teamSize;
    },
    originalPriceFormatted() {
      return currencyFormatter[this.subscriptionPlan.currency](this.originalPrice);
    },
    showOriginalPrice() {
      return this.subscriptionPlan.hasDiscount || this.teamSize >= this.minDiscountableTeamSize;
    },
    minDiscountableTeamSize() {
      const noDiscountPriceTier = this.subscriptionPlan.tiers[0];

      return noDiscountPriceTier.up_to + 1;
    },
    finalPrice() {
      // Tiered pricing structure
      //
      // [
      //   {
      //     "up_to": 20,
      //     "unit_amount": 22499
      //   }, {
      //     "up_to": 100,
      //     "unit_amount": 21399
      //   }, {
      //     "up_to": "inf",
      //     "unit_amount": 20299
      //   }
      // ]
      let price = calculateTieredPrice(this.teamSize, this.subscriptionPlan.tiers);

      if(this.subscriptionPlan.hasDiscount) {
        price = price * (100.0 - this.subscriptionPlan.coupon.discountPercentage) / 100.0;
      }

      return price;
    },
    finalPriceFormatteed() {
      return currencyFormatter[this.subscriptionPlan.currency](this.finalPrice);
    }
  },
  data() {
    return {
      teamSize: MIN_TEAM_SIZE + 1,
      1: MIN_TEAM_SIZE
    }
  },
  props: {
    subscriptionPlan: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  i18n: {
    messages: {
      en: {
        planDescription: "Team plan is perfect for your team to take the entire SQL Habit Course (%[totalCourseLessonsCount] lessons and exercises), dive into all %[practiceExercisesCount] Practice exercises and stay up to date with all regularly added content. Teams of {minDiscountableTeamSize}+ seats will get a discount.",
        perBillingPeriod: {
          day: "day",
          week: "week",
          month: "mo",
          year: "year"
        },
        billingPeriod: {
          day: "daily",
          week: "weekly",
          month: "monthly",
          year: "once a year"
        },
      }
    }
  }
}
</script>

<style lang="scss">
.team-subscription-size-calculator {
  $root: &;

  position: relative;
  min-width: $px384;
  max-width: $px496;
  padding: $px24;

  border-radius: $px8;

  background-color: $grey-10;

  &__headline {
    @include h3;
    font-weight: 700;
  }

  &__size-label {
    @include font-label;
  }

  &__size-input {
    width: 100%;
    padding: $px8;

    border: $px2 solid $grey-7;
    border-radius: $px4;
    outline: none;

    font-size: $px24;
    line-height: 1.5;

    appearance: none;

    &::placeholder {
      font-size: $px24;
      line-height: 1.5;
      color: $grey-7;
    }
  }

  &__cta {
    display: inline-block;
    width: 100%;
    margin-top: $px16;

    @include breakpoint($md) {
      width: auto;
    }
  }

  &__prices {
    display: flex;
    align-items: center;
  }

  &__original-price {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    text-decoration: line-through;
    color: $grey-7;
  }

  &__final-price {
    font-size: $px32;
    line-height: $px32;
    font-weight: 700;
  }

  &__per-year {
    margin-top: auto;

    font-size: $px20;
    line-height: 1;
  }

  &__billing-price {
    margin-top: $px8;

    @include font-small;
    text-align: center;

    &--striked {
      color: $grey-2;
      text-decoration: line-through;
    }
  }

  &__savings {
    position: absolute;
    right: $px16;
    top: 0;
    transform: translateY(-50%);
    padding: $px4 $px12;

    border-radius: $px16;

    background-color: $purple-5;

    color: $white;
    font-size: 15px;
    font-weight: 700;
  }
}
</style>
