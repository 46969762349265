<template>
<transition name="fade" :duration="100">
  <div
    class="selected-text-menu"
    :class="{
      'selected-text-menu--active': isVisible
    }"
    v-if="isVisible">
    <div
      class="selected-text-menu__icon-wrapper mr-16"
      @click.prevent="openEmailForm">
      <EnvelopeIcon class="selected-text-menu__icon selected-text-menu__icon--email" />
    </div>

    <div
      class="selected-text-menu__icon-wrapper mr-16"
      @click.prevent="openAiAssistantForm">
      <RobotIcon class="selected-text-menu__icon" />
    </div>
  </div>
</transition>
</template>

<script>
import EnvelopeIcon from "@images/font_awesome/envelope-light.svg";
import RobotIcon from "@images/font_awesome/robot-light.svg";

export default {
  name: "SelectedTextMenu",
  components: {
    EnvelopeIcon,
    RobotIcon
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    openEmailForm() {
      this.$emit("selectForm", "email");
    },
    openAiAssistantForm() {
      this.$emit("selectForm", "ai-assistant");
    }
  }
}
</script>

<style lang="scss">
.selected-text-menu {
  &__icon-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border-radius: 50%;

    background-color: $orange-6;

    cursor: pointer;

    &:hover {
      background-color: $orange-7;
    }

    &:not(:last-child) {
      margin-bottom: $px12;
    }
  }

  &__icon {
    width: $px24;
    height: $px24;
    flex-shrink: 0;

    color: $white;

    &--email {
      width: $px20;
      height: $px20;
    }
  }
}
</style>
