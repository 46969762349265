<template>
  <div
    class="mock-interview-exercise"
    :class="{
      'mock-interview-exercise--full-screen': fullScreen
    }">
    <div class="mock-interview-exercise__name mb-16">
      {{ name }}
    </div>

    <TabsMenu
      class="mb-48"
      :items="{
        description: 'Description',
        sql_editor: 'SQL Editor',
        db_info: 'Database Info',
        sql_reference: 'SQL Reference',
      }"
      :activeItem="activeTab"
      @activeTab="handleTabChange"/>

    <Tab :active="activeTab === 'description'">
      <div class="mock-interview-exercise__description-tab max-width-720">
        <div
          class="mock-interview-exercise__description"
          v-html="description" />

        <div
          v-if="interviewItem.item.table_and_column_highlights && !interviewItem.item.has_schema_visualization_link"
          class="mock-interview-exercise__dataset-highlight-url">
          <span v-html="$options.filters.md(`:mag: You can`)" />
          <a
            :href="`https://sqlhabit.github.io/sql_schema_visualizer/databases/${interviewItem.item.dataset_slug}?highlights=${interviewItem.item.table_and_column_highlights}`"
            target="_blank">lookup database schema visualization here.</a>
        </div>

        <div class="mock-interview-exercise__current-query mb-32">
          <h3>Your query</h3>

          <div v-if="query">
            <pre>
              <code ref="user_query" class="language-pgsql hljs" v-html="query" />
            </pre>
          </div>

          <div
            v-if="!query"
            v-html="$options.filters.md(`:point_up: Prepare a query in the SQL Editor and submit it from here when you're ready.`)" />
        </div>

        <div
          class="mock-interview-exercise__actions">

          <button
            class="mock-interview-exercise__submit btn btn--lg"
            :class="{
              'btn--disabled': !queryValid || formLock
            }"
            :disabled="!queryValid"
            @click.prevent="handleSubmit">

            <span v-if="formLock">
              SUBMITTING YOUR QUERY...
            </span>

            <span v-else>
              SUBMIT CURRENT QUERY
            </span>
          </button>

          <a
            v-if="showSkipLink"
            href="#"
            class="mock-interview-exercise__skip"
            :class="{
              'mock-interview-exercise__skip--disabled': formLock
            }"
            @click.prevent="handleSkip">

            Skip
          </a>
        </div>
      </div>
    </Tab>

    <Tab :active="activeTab === 'sql_editor'">
      <div class="mock-interview-exercise__workspace max-width-960">
        <SQLEditor
          ref="sqlEditor"
          :interviewItem="interviewItem.item"
          :formLock="formLock"
          v-on:query-changed="queryChanged"
          :initialQuery="initialQuery" />
      </div>
    </Tab>

    <Tab :active="activeTab === 'db_info'">
      <DatabaseDocumentation
        :showDatasetMenu="false"
        :forceDatasetId="datasetId" />
    </Tab>

    <Tab :active="activeTab === 'sql_reference'">
      <SqlReference />
    </Tab>

    <transition
      enter-active-class="slide-in-down"
      leave-active-class="slide-in-up">

      <ConfirmationPopup
        v-if="confirmationPopupShown"
        :copy="confirmationPopupCopy"
        @submit="handleConfirmationPopup" />
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import SQLEditor from "components/mock-interview/sql-editor";
import ConfirmationPopup from "components/mock-interview/confirmation-popup";
import TabsMenu from "components/tabs-menu";
import Tab from "components/tab";
import DatabaseDocumentation from "components/database-documentation";
import SqlReference from "components/sql-reference";
import { loadMathjax } from "controllers/mathjax";
import { highlightAll, forceHighlightNode } from "modules/code-highlight";

export default {
  components: {
    SQLEditor,
    ConfirmationPopup,
    TabsMenu,
    Tab,
    DatabaseDocumentation,
    SqlReference
  },
  data() {
    return {
      activeTab: "description",
      query: null,
      formLock: false,
      confirmationPopupShown: false,
      confirmationPopupCopy: null,
      confirmationPopupAction: null,
      queryValid: false
    }
  },
  props: {
    showSkipLink: {
      type: Boolean,
      default: true
    },
    interviewItem: {
      type: Object
    },
    mockInterviewId: {
      type: String
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    initialQuery: {
      type: String
    }
  },
  computed: {
    name() {
      return this.interviewItem.item.name;
    },
    description() {
      return this.interviewItem.item.description;
    },
    datasetId() {
      return this.interviewItem.item.dataset_id;
    }
  },
  watch: {
    interviewItem(newVal, oldVal) {
      this.startExercise();

      this.query = null;

      this.$refs.sqlEditor.clearQuery();
    },
    query(newVal) {
      if (newVal.toString().length > 10) {
        this.queryValid = true;
      } else {
        this.queryValid = false;
      }
    }
  },
  methods: {
    handleTabChange(newTab) {
      this.activeTab = newTab;

      if(newTab === "description" && this.$refs.user_query) {
        forceHighlightNode(this.$refs.user_query);
      }
    },
    queryChanged(query) {
      this.query = query;
    },
    startExercise() {
      let apiPath = `/api/mock-interviews/interview_items/${this.interviewItem.id}/start`;

      let formData = new FormData();

      formData.append("mock_interview_id", this.mockInterviewId);

      axios.patch(apiPath, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
      }).catch(error => {
      });
    },
    handleSubmit() {
      if(this.showSkipLink) {
        this.confirmationPopupCopy = "Are you sure you want to submit your query as the solution and continue?";
      } else {
        this.confirmationPopupCopy = "Are you sure you want to submit your query and finish the exercise?";
      }
      this.confirmationPopupAction = "submit";
      this.confirmationPopupShown = true;
    },
    handleSkip() {
      this.confirmationPopupCopy = "Are you sure you want to skip this exercise?";
      this.confirmationPopupAction = "skip";
      this.confirmationPopupShown = true;
    },
    handleConfirmationPopup(confirm) {
      if(confirm) {
        if(this.confirmationPopupAction === "submit") {
          this.submitSolution();
        } else if(this.confirmationPopupAction === "skip") {
          this.skipExercise();
        } else {
          this.confirmationPopupShown = false;
        }
      } else {
        this.confirmationPopupShown = false;
      }
    },
    submitSolution() {
      if(this.formLock) {
        return;
      }

      this.confirmationPopupShown = false;

      this.formLock = true;

      let apiPath = `/api/mock-interviews/interview_items/${this.interviewItem.id}/evaluate`;

      let formData = new FormData();

      formData.append("mock_interview_id", this.mockInterviewId);

      formData.append("interview_item[query]", this.query);

      axios.patch(apiPath, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        this.$emit("exercise-finished");
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.formLock = false;
      });
    },
    skipExercise() {
      if(this.formLock) {
        return;
      }

      this.confirmationPopupShown = false;

      this.formLock = true;

      let apiPath = `/api/mock-interviews/interview_items/${this.interviewItem.id}/skip`;

      let formData = new FormData();

      formData.append("mock_interview_id", this.mockInterviewId);

      axios.patch(apiPath, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        this.$emit("exercise-finished");
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.formLock = false;
      });
    }
  },
  mounted() {
    this.startExercise();

    this.$nextTick(() => {
      highlightAll();
      loadMathjax();

      if(this.$refs.user_query) {
        forceHighlightNode(this.$refs.user_query);
      }
    });
  }
}
</script>

<style lang="scss">
@keyframes slideInDown {
  from {
    transform: translate3d(-50%, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(-50%, -100%, 0);
  }
}

.slide-in-up {
  animation: slideInUp 0.2s ease-in-out both;
}

.slide-in-down {
  animation: slideInDown 0.2s ease-in-out both;
}

.mock-interview-exercise {
  $root: &;

  overflow: hidden;

  &__name {
    @include h1;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__submit {
    margin-right: $px24;
  }

  &__description {
    margin-bottom: $px24;

    p:first-child {
      margin-top: 0;
    }

    .table {
      overflow-x: auto;
    }

    table {
      border: $px1 solid $grey-7;
      border-radius: $px4;
      border-collapse: collapse;

      tr:first-child {
        background-color: $grey-7;

        font-weight: 700;
      }

      td {
        white-space: nowrap;
      }

      tr {
        &:nth-child(even) {
          background-color: $grey-10;
        }
      }

      th,
      td {
        padding: $px4 $px8;

        text-align: left;
      }
    }
  }

  &__left-column {
    width: 45%;
    padding-right: $px32;
  }

  &__current-query {
    pre {
      white-space: pre-line;
    }

    code {
      white-space: pre;
    }
  }

  &__sql-editor {
    width: 55%;
  }

  &__workspace {
  }

  &__skip {
    @include font-link;

    &--disabled {
      color: $grey-7;
    }
  }
}
</style>
