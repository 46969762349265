<template>
  <div
    :class="{
      'block': loaded,
      'display-none': !loaded
    }">
    <table class="info-table info-table--scroll-auto info-table--no-border info-table--p-8">
      <thead>
        <th class="pointer" @click="sort('content_type_name')">Type</th>
        <th class="pointer" @click="sort('content_name')">Name</th>
        <th class="pointer" @click="sort('chatper_name')">Chapter</th>
        <th class="pointer" @click="sort('unix_timestamp')">Added</th>
        <th></th>
      </thead>

      <tbody>
        <tr
          v-for="bookmark in sortedBookmarks"
          :key="bookmark.content_path">
          <td>
            <div class="flex">
              <span class="inline-pill">{{ bookmark.content_type_name }}</span>
            </div>
          </td>
          <td><a :href="bookmark.content_path">{{ bookmark.content_name }}</a></td>
          <td>{{ bookmark.chapter_name }}</td>
          <td>
            <div class="font-small">
              {{ bookmark.timestamp }}
            </div>
          </td>
          <td>
            <div class="px-16">
              <Bookmark
                :contentType="bookmark.content_type"
                :contentSlug="bookmark.content_slug"
                :iconOnly="true"
                :initActive="true"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import Bookmark from "./bookmark.vue";
import log from "modules/log";
import { notify } from "modules/notify";

export default {
  name: "BookmarkTable",
  components: {
    Bookmark
  },
  data() {
    return {
      loaded: false,
      bookmarks: [],
      sortColumn: null,
      sortOrder: 1 // 1 - desc, -1 - asc
    }
  },
  computed: {
    sortedBookmarks() {
      if(!this.sortColumn) {
        return this.bookmarks;
      }

      return this.bookmarks.sort((a, b) => {
        if(a[this.sortColumn] > b[this.sortColumn]) {
          return this.sortOrder;
        } else {
          return -this.sortOrder;
        }
      })
    }
  },
  methods: {
    toggleSortOrder() {
      this.sortOrder *= -1;
    },
    sort(columnName) {
      if(this.sortColumn === columnName) {
        this.toggleSortOrder();
      } else {
        this.sortColumn = columnName;
        this.sortOrder = 1;
      }
    }
  },
  mounted() {
    axios.get("/api/bookmarks.json", {}, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
      }
    }).then(response => {
      this.bookmarks = response.data;
    }).catch((error) => {
      log(error);

      notify({
        message: "Oops, something went wrong. Please, try again or ping support@sqlhabit.com",
        type: "error"
      });
    }).finally(() => {
      const $spinner = document.querySelector(".spinner")

      if($spinner) {
        $spinner.remove();
      }

      this.$nextTick(() => {
        this.loaded = true;
      });
    });
  }
}
</script>

<style>

</style>
