<template>
  <div class="choose-subscription-step">
    <div class="choose-subscription-step__container">
      <BlobHero
        class="mb-48"
        headline="Your growth journey starts here"
        subheadline="Unlock a new level in your career or business by learning how to make data-informed decisions."
        leftBlob="circle"
        rightBlob="triangle" />

      <div
        v-if="showSpinner"
        class="flex justify-center mb-48">
        <Spinner />
      </div>

      <div
        v-else
        class="choose-subscription-step__pricing-plans mb-48">
        <div class="choose-subscription-step__pricing-plan">
          <SubscriptionPricingPlan
            :modifiers="[]"
            :subscriptionPlan="monthlyPlan"
            :finalMonthlyPrice="monthlyPlan.finalPrice"
            :originalMonthlyPrice="monthlyPlan.originalPrice" />
        </div>
        <div class="choose-subscription-step__pricing-plan">
          <SubscriptionPricingPlan
            :modifiers="[]"
            :subscriptionPlan="yearlyPlan"
            :finalMonthlyPrice="monthlyPlan.finalPrice"
            :originalMonthlyPrice="monthlyPlan.originalPrice" />
        </div>
      </div>

      <div class="choose-subscription-step__free-pricing-plan mb-72">
        <div
          class="choose-subscription-step__free-pricing-plan-copy mb-12"
          v-html="$options.filters.md($t('freePlanDescription'))" />

        <button
          @click.prevent="$emit('skip')"
          class="btn">
          Continue with a free plan
        </button>
      </div>

      <div class="mb-48">
        <FeatureList />
      </div>

      <div class="mb-48">
        <Reviews />
      </div>

      <div class="mb-48">
        <ClientCompanies />
      </div>

      <div class="mb-48">
        <FAQ extraQuestionsSlug="subscriptions" />
      </div>
    </div>
  </div>
</template>

<script>
import BlobHero from "components/blob-hero";
import Spinner from "components/spinner";
import SubscriptionPricingPlan from "components/subscription-pricing-plan";
import FeatureList from "components/feature-list";
import Reviews from "components/reviews";
import axios from "axios";
import BookmarkEmpty from "@images/font_awesome/bookmark-light.svg";
import ClientCompanies from "components/client-companies.vue";
import FAQ from "components/faq";

export default {
  name: "ChooseSusbcriptionStep",
  components: {
    BlobHero,
    Spinner,
    SubscriptionPricingPlan,
    BookmarkEmpty,
    FeatureList,
    Reviews,
    ClientCompanies,
    FAQ
  },
  data() {
    return {
      monthlyPlan: null,
      yearlyPlan: null,
      icon: "bookmark-light"
    }
  },
  computed: {
    showSpinner() {
      return !this.monthlyPlan && !this.yearlyPlan;
    }
  },
  i18n: {
    messages: {
      en: {
        freePlanDescription: "Not ready to subscribe? Check out %[freeLessonsCount] free lessons and %[freePracticeExercisesCount] practice exercises."
      }
    }
  },
  mounted() {
    axios.get("/api/subscription-prices.json", {
      params: {
        billing_periods: ["month", "year"]
      }
    }).then(response => {
      const subscriptionPlans = response.data.subscriptionPlans;

      this.monthlyPlan = subscriptionPlans.find(p => p.billingPeriod === "month");
      this.yearlyPlan = subscriptionPlans.find(p => p.billingPeriod === "year");
    });
  }
}
</script>

<style lang="scss">
.choose-subscription-step {
  &__free-pricing-plan {
    padding: $px24;

    text-align: center;
  }

  &__pricing-plans {
    display: grid;
    grid-template-columns: 1fr;
    gap: $px24;

    @include breakpoint($md) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
