import qs from "qs";

export const mountContentItemRunQueryLinks = () => {
  if(!window.state.contentItem) {
    return;
  }

  document.querySelectorAll("pre code.language-pgsql.hljs").forEach((code) => {
    const $pre = code.closest("pre");

    if($pre?.classList?.contains("js-no-run-query-link")) {
      return;
    }

    if($pre?.querySelector(".js-run-query-link")) {
      return;
    }

    let linkToPlayground = document.createElement("a");
    let query = encodeURIComponent(code.textContent);
    const datasetId = code.dataset.datasetId || window.state.datasetId;

    let urlParams = qs.stringify({
      query: {
        body: query,
        content_id: window.state.contentItem.contentId,
        content_type: window.state.contentItem.contentType,
        dataset_id: datasetId
      }
    });

    linkToPlayground.text = "Run query";
    linkToPlayground.href = `/playground?${urlParams}`;
    linkToPlayground.classList.add("mr-16");
    linkToPlayground.classList.add("run-query-link");
    linkToPlayground.classList.add("js-run-query-link");
    linkToPlayground.target = "_blank";

    linkToPlayground.setAttribute("data-query", query);
    linkToPlayground.setAttribute("data-dataset-id", datasetId);

    code.parentNode.insertBefore(linkToPlayground, code.nextSibling);

    if($pre.dataset.chartLink) {
      let linkToChart = document.createElement("a");

      linkToChart.text = "Chart";
      linkToChart.href = `#`;
      linkToChart.classList.add("font-small");
      linkToChart.classList.add("js-chart-query-link");

      linkToChart.setAttribute("data-query", query);
      linkToChart.setAttribute("data-dataset-id", datasetId);

      code.parentNode.appendChild(linkToChart);
    }
  });
};


if(window.state) {
  if (!window.hasOwnProperty("addedQueryLinkEventListeners")) {
    window.addedQueryLinkEventListeners = false;
  }

  const addQueryLinkEventListeners = () => {
    if (window.addedQueryLinkEventListeners) {
      return
    }

    document.addEventListener("click", (e) => {
      if(e.target && e.target.matches(".js-chart-query-link")) {
        if(window.state.editor) {
          e.preventDefault();

          let $runQueryLink = e.target;

          const linkQuery = decodeURIComponent($runQueryLink.getAttribute("data-query"));

          window.state.editor.getSession().setValue(linkQuery);

          window.state.query.body = linkQuery;
          window.state.query.datasetId = parseInt($runQueryLink.dataset.datasetId, 10);
          window.state.runQueryScrollY = window.scrollY;

          document.querySelector(".tabs-menu__button[data-tab='chart']").click();

          if(window.scrollTo) {
            window.scrollTo(0, 0);
          }
        }
      }
    });

    document.addEventListener("click", (e) => {
      if(e.target && e.target.matches(".js-run-query-link")) {
        let $queryForm = document.querySelector("#query-form");

        if($queryForm && $queryForm.querySelector(".js-submit-query-form") && window.state.editor) {
          e.preventDefault();

          let $runQueryLink = e.target;

          const linkQuery = decodeURIComponent($runQueryLink.getAttribute("data-query"));

          window.state.editor.getSession().setValue(linkQuery);

          window.state.query.body = linkQuery;
          window.state.query.datasetId = parseInt($runQueryLink.dataset.datasetId, 10);
          window.state.runQueryScrollY = window.scrollY;

          let $submitButton = $queryForm.querySelector(".js-submit-query-form");
          $submitButton.click();

          document.querySelector(".tabs-menu__button[data-tab='sql_editor']").click();

          if(window.scrollTo) {
            window.scrollTo(0, 0);
          }
        }
      }
    });

    window.addedQueryLinkEventListeners = true;
  }

  addQueryLinkEventListeners();

  mountContentItemRunQueryLinks();

  let $mockInterviewExerciseEvaluations = document.querySelectorAll(".mock-interview-exercise-evaluation");
  $mockInterviewExerciseEvaluations.forEach($exerciseEvaluation => {
    let contentId = $exerciseEvaluation.dataset.contentId;
    let datasetId = $exerciseEvaluation.dataset.datasetId;

    $exerciseEvaluation.querySelectorAll(".language-pgsql.hljs").forEach((code) => {
      if(code.closest("pre").classList.contains("js-no-run-query-link")) {
        return;
      }

      if(code.closest("pre").querySelector(".js-run-query-link")) {
        return;
      }

      let linkToPlayground = document.createElement("a");
      let query = encodeURIComponent(code.textContent);

      let urlParams = qs.stringify({
        query: {
          body: query,
          content_id: contentId,
          content_type: "MockInterviews::Exercise",
          dataset_id: datasetId
        }
      });

      linkToPlayground.text = "Run query";
      linkToPlayground.href = `/playground?${urlParams}`;
      linkToPlayground.classList.add("run-query-link");
      linkToPlayground.classList.add("js-run-query-link");
      linkToPlayground.target = "_blank";

      linkToPlayground.setAttribute("data-query", query);
      linkToPlayground.setAttribute("data-dataset-id", datasetId);

      code.parentNode.insertBefore(linkToPlayground, code.nextSibling);
    });
  });

  let $mdnPage = document.querySelector(".js-mdn-page");
  if ($mdnPage) {
    $mdnPage.querySelectorAll(".language-pgsql.hljs").forEach(($code) => {
      if($code.closest("pre").classList.contains("js-no-run-query-link")) {
        return;
      }

      if($code.closest("pre").querySelector(".js-run-query-link")) {
        return;
      }

      let linkToPlayground = document.createElement("a");
      const query = encodeURIComponent($code.textContent);
      const datasetId = $code.dataset.datasetId || $code.closest("pre").dataset.datasetId || 1;

      let urlParams = qs.stringify({
        query: {
          body: query,
          dataset_id: datasetId
        }
      });

      linkToPlayground.text = "Run query";
      linkToPlayground.href = `/playground?${urlParams}`;
      linkToPlayground.classList.add("run-query-link");
      linkToPlayground.classList.add("js-run-query-link");

      if (window.state) {
        window.state.runQueryScrollY = window.scrollY;
      }

      if (window.state && window.state.loggedIn !== true) {
        linkToPlayground.classList.add("js-open-signup-popup");
      }
      linkToPlayground.target = "_blank";

      linkToPlayground.setAttribute("data-query", query);
      linkToPlayground.setAttribute("data-dataset-id", datasetId);
      linkToPlayground.setAttribute("data-guest-popup", "mdn-signup-popup");

      $code.parentNode.insertBefore(linkToPlayground, $code.nextSibling);
    });
  }
}
