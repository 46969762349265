var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mock-interview-exercise",class:{
    'mock-interview-exercise--full-screen': _vm.fullScreen
  }},[_c('div',{staticClass:"mock-interview-exercise__name mb-16"},[_vm._v("\n    "+_vm._s(_vm.name)+"\n  ")]),_vm._v(" "),_c('TabsMenu',{staticClass:"mb-48",attrs:{"items":{
      description: 'Description',
      sql_editor: 'SQL Editor',
      db_info: 'Database Info',
      sql_reference: 'SQL Reference',
    },"activeItem":_vm.activeTab},on:{"activeTab":_vm.handleTabChange}}),_vm._v(" "),_c('Tab',{attrs:{"active":_vm.activeTab === 'description'}},[_c('div',{staticClass:"mock-interview-exercise__description-tab max-width-720"},[_c('div',{staticClass:"mock-interview-exercise__description",domProps:{"innerHTML":_vm._s(_vm.description)}}),_vm._v(" "),(_vm.interviewItem.item.table_and_column_highlights && !_vm.interviewItem.item.has_schema_visualization_link)?_c('div',{staticClass:"mock-interview-exercise__dataset-highlight-url"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.md(`:mag: You can`))}}),_vm._v(" "),_c('a',{attrs:{"href":`https://sqlhabit.github.io/sql_schema_visualizer/databases/${_vm.interviewItem.item.dataset_slug}?highlights=${_vm.interviewItem.item.table_and_column_highlights}`,"target":"_blank"}},[_vm._v("lookup database schema visualization here.")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mock-interview-exercise__current-query mb-32"},[_c('h3',[_vm._v("Your query")]),_vm._v(" "),(_vm.query)?_c('div',[_c('pre',[_vm._v("            "),_c('code',{ref:"user_query",staticClass:"language-pgsql hljs",domProps:{"innerHTML":_vm._s(_vm.query)}}),_vm._v("\n          ")])]):_vm._e(),_vm._v(" "),(!_vm.query)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.md(`:point_up: Prepare a query in the SQL Editor and submit it from here when you're ready.`))}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"mock-interview-exercise__actions"},[_c('button',{staticClass:"mock-interview-exercise__submit btn btn--lg",class:{
            'btn--disabled': !_vm.queryValid || _vm.formLock
          },attrs:{"disabled":!_vm.queryValid},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.formLock)?_c('span',[_vm._v("\n            SUBMITTING YOUR QUERY...\n          ")]):_c('span',[_vm._v("\n            SUBMIT CURRENT QUERY\n          ")])]),_vm._v(" "),(_vm.showSkipLink)?_c('a',{staticClass:"mock-interview-exercise__skip",class:{
            'mock-interview-exercise__skip--disabled': _vm.formLock
          },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleSkip.apply(null, arguments)}}},[_vm._v("\n\n          Skip\n        ")]):_vm._e()])])]),_vm._v(" "),_c('Tab',{attrs:{"active":_vm.activeTab === 'sql_editor'}},[_c('div',{staticClass:"mock-interview-exercise__workspace max-width-960"},[_c('SQLEditor',{ref:"sqlEditor",attrs:{"interviewItem":_vm.interviewItem.item,"formLock":_vm.formLock,"initialQuery":_vm.initialQuery},on:{"query-changed":_vm.queryChanged}})],1)]),_vm._v(" "),_c('Tab',{attrs:{"active":_vm.activeTab === 'db_info'}},[_c('DatabaseDocumentation',{attrs:{"showDatasetMenu":false,"forceDatasetId":_vm.datasetId}})],1),_vm._v(" "),_c('Tab',{attrs:{"active":_vm.activeTab === 'sql_reference'}},[_c('SqlReference')],1),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":"slide-in-down","leave-active-class":"slide-in-up"}},[(_vm.confirmationPopupShown)?_c('ConfirmationPopup',{attrs:{"copy":_vm.confirmationPopupCopy},on:{"submit":_vm.handleConfirmationPopup}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }