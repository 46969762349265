<template>
<div
  :class="blockClass">
  <div class="subscription-pricing-plans__plans">
    <SubscriptionPricingPlan
        class="subscription-pricing-plans__plan"
      v-for="subscriptionPlan in subscriptionPlans"
      :key="subscriptionPlan.slug"
      :subscriptionPlan="subscriptionPlan"
      :finalMonthlyPrice="monthlyPlan.finalPrice"
      :originalMonthlyPrice="monthlyPlan.originalPrice"
      />
  </div>
</div>
</template>

<script>
import SubscriptionPricingPlan from "components/subscription-pricing-plan";
import { bemClass } from "helpers/bem-class";

export default {
  name: "SubscriptionPricingPlans",
  components: {
    SubscriptionPricingPlan
  },
  props: {
    subscriptionPlans: {
      type: Array,
      required: true
    }
  },
  computed: {
    monthlyPlan() {
      return this.subscriptionPlans.find(p => p.billingPeriod === "month");
    },
    blockClass() {
      let modifiers = [
        this.subscriptionPlans.length
      ];

      return bemClass({
        baseClass: "subscription-pricing-plans",
        modifiers
      });
    }
  },
  mounted() {
    const $spinner = document.querySelector(".js-subscription-pricing-plans-spinner");
    if($spinner) {
      $spinner.remove();
    }
  }
}
</script>

<style lang="scss">
.subscription-pricing-plans {
  $root: &;

  &__plans {
    display: flex;
    gap: $px32;
    align-items: flex-start;
    flex-direction: column-reverse;

    @include breakpoint($md) {
      display: grid;
      flex-direction: row;
      gap: $px16;
    }
  }

  &__plan {
    width: 100%;
  }

  &--2 {
    #{$root}__plans {
      @include breakpoint($md) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &--3 {
    #{$root}__plans {
      @include breakpoint($md) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
</style>
