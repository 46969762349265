<template>
  <div class="first-name-step pt-32">
    <div class="first-name-step__container">
      <div class="first-name-step__headline mb-48">
        <div class="flex align-center">
          <img
            class="first-name-step__avatar w-48 h-48 radius-50 mr-8"
            :src="require('@images/avatars/avatar.png')"
            alt="Anatoli, the author of SQL Habit">
          <span class="h2">Hi, it's Anatoli, the author of SQL Habit</span>
        </div>
      </div>

      <div class="first-name-step__description">
        Let's get to know each other. May I know your first name to make things more personal?
      </div>

      <div class="first-name-step__form">
        <input
          type="text"
          placeholder="Your first name"
          name="firstName"
          class="first-name-step__input"
          v-model="value"
          autofocus
          v-on:change="handleValueChange">

        <div class="first-name-step__input-hint">
          Your first name will be used in our emails and messages, when I or AI Coach will answer your questions. Your first name will never be shared with 3rd parties.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstNameStep",
  data() {
    return {
      value: ""
    }
  },
  props: ["firstName"],
  methods: {
    handleValueChange() {
      this.$emit("toggle-first-name", this.value);
    }
  },
  mounted() {
    this.value = this.firstName;
  }
}
</script>

<style lang="scss">
.first-name-step {
  max-width: 720px;

  &__avatar {
    border: $px4 solid $blue-9;
  }

  &__description {
    margin-bottom: $px32;

    @include font-large;
  }

  &__occupations {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $px32;
  }

  &__occupation {
    margin-bottom: $px16;

    &:not(:last-child) {
      margin-right: $px16;
    }
  }

  &__form {
    @include breakpoint($sm) {
      max-width: 400px;
    }
  }

  &__input {
    margin-bottom: $px16;
    padding: $px8;

    outline: none;
    appearance: none;
    border: 0;
    border-bottom: $px1 solid $grey-9;

    color: $grey-1;

    &::placeholder {
      @include font-btn;
      color: $grey-7;
    }
  }

  &__input-hint {
    @include font-small;
  }
}
</style>
