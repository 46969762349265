<template>
  <div class="selected-text-email-form">
    <CloseIcon class="selected-text-email-form__close" @click="close" />

    <div class="selected-text-email-form__container">
      <div class="selected-text-email-form__text-fragment">
        ...{{ trimmedTextFragment }}...
      </div>

      <form action="#" class="selected-text-email-form__form" @submit.prevent="submitFeedback">
        <input v-model="name" class="selected-text-email-form__name" placeholder="Your name" />

        <textarea
          v-model="comment"
          class="selected-text-email-form__comment"
          placeholder="Message to Anatoli" />

        <button class="selected-text-email-form__submit btn btn--lg btn--block btn--full-width" :disabled="!formIsValid">
          SEND
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@images/font_awesome/x-light.svg";
import QuoteIcon from "@images/font_awesome/quote-right-light.svg";
import axios from "axios";
import { notify } from "modules/notify";
import Sentry from "modules/sentry";
import { mapGetters } from "vuex";
import { isMacOs } from "modules/is-mac-os";

export default {
  name: "SelectedTextEmailForm",
  components: {
    CloseIcon,
    QuoteIcon
  },
  computed: {
    ...mapGetters({
      userHandle: "user/userHandle"
    }),
    trimmedTextFragment() {
      return this.textFragment?.slice(0, 150);
    },
    formIsValid() {
      return this.name && this.comment && this.textFragment;
    }
  },
  data() {
    return {
      name: null,
      comment: null,
      textFragment: this.selectedText
    }
  },
  props: ["selectedText"],
  methods: {
    close() {
      this.textFragment = "";
      this.comment = "";

      this.$emit("close");
    },
    submitFeedback() {
      let formData = new FormData();

      formData.append(
        "page_feedback[feedback]",
        `<strong>Text fragment</strong>\r\n${this.trimmedTextFragment}\r\n\r\n<strong>Comment</strong>\r\n${this.comment}`
      );

      formData.append(
        "page_feedback[email]",
        window.userEmail
      );

      formData.append(
        "page_feedback[name]",
        this.name
      );

      axios.post("/api/page_feedbacks", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        notify({
          message: "The message has been sent successfully!"
        });

        this.close();
      }).catch(function (error) {
        notify({
          message: "Smth went wrong",
          type: "error"
        });

        Sentry.captureException(error);
      });
    },
    handleKeydown(event) {
      if(event.key === "Escape" || event.key === "Esc") {
        this.close();
      }
    },
    handleOutsideClick(event) {
      if(event.target.closest(".selected-text-email-form") ||
        event.target.closest(".selected-text-menu") ||
        event.target.closest(".selected-text-menu-container") ||
        event.target.closest(".selected-text-menu-forms")) {

        return;
      }

      this.close();
    },
    handleCmdEnter(e) {
      if (!((isMacOs() ? e.metaKey : e.ctrlKey) && e.keyCode == 13)) {
        return;
      }

      if (!this.formIsValid) {
        return;
      }

      this.submitFeedback();
    }
  },
  mounted() {
    this.name = this.userHandle;

    document.addEventListener("keydown", this.handleKeydown);
    document.addEventListener("click", this.handleOutsideClick);
    document.addEventListener("keydown", this.handleCmdEnter);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeydown);
    document.removeEventListener("click", this.handleOutsideClick);
    document.removeEventListener("keydown", this.handleCmdEnter);
  }
}
</script>

<style lang="scss">
.selected-text-email-form {
  position: relative;
  z-index: 2000;
  min-width: $px320;
  padding: $px48 $px8 $px8;

  box-shadow: 0 0 $px8 $grey-9;
  border: $px1 solid $grey-9;
  border-radius: $px4;

  background-color: $white;

  &__name {
    width: 100%;
    padding: $px8;
    margin-bottom: $px16;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__text-fragment {
    position: relative;
    max-width: $px320;
    margin-bottom: $px16;

    @include font-small;
    color: $grey-1;
  }

  &__comment {
    width: 100%;
    height: $px96;
    padding: $px8;
    margin-bottom: $px16;
    display: block;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__close {
    position: absolute;
    top: $px8;
    right: $px8;
    width: $px20;
    height: $px20;

    cursor: pointer;
    transition: all .25s;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
