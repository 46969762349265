import axios from "axios";
import mountBookmarks from "controllers/bookmark";
import mountMarkAsFinished from "controllers/mark-as-finished";
import mountContentItemRating from "controllers/content-item-rating";
import updateFullContentLinks from "modules/update-full-content-links";
import { mountContentItemRunQueryLinks } from "modules/playground-link-for-queries";
import { mountLessonSolvedCheckmark } from "modules/content-item-solved-check-mark";
import { initSelectedTextMenu } from "controllers/selected-text-menu";
import { mountMathjax } from "controllers/mathjax";
import { highlightAll } from "modules/code-highlight";
import { enableHeadlineLinks } from "modules/headline-links";
import { store } from "store"

const loadLessonContentPartial = ($lessonContent, userSegment) => {
  const { slug } = $lessonContent.dataset;

  const params = {
    slug
  };

  axios.get("/api/partials/full-lesson-content.json", {
    params: params
  }).then(response => {
    const params = response.data;

    if(params.partial) {
      $lessonContent.outerHTML = params.partial;

      store.dispatch("lesson/setSlug", slug);
      store.dispatch("lesson/setCompletedFlag", params.lesson_completed);

      mountBookmarks();
      mountMarkAsFinished();
      mountContentItemRating();
      highlightAll();
      mountMathjax();
      updateFullContentLinks(userSegment);
      mountContentItemRunQueryLinks();
      mountLessonSolvedCheckmark(params.lesson_completed);
      enableHeadlineLinks();
      initSelectedTextMenu();
    }
  });
}

export default loadLessonContentPartial;
