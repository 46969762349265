<template>
  <div class="welcome-checklist">
    <a
      class="welcome-checklist__close-link"
      @click.prevent="skip"
      href="#">
      Skip
    </a>

    <div class="welcome-checklist__container">
      <header class="welcome-checklist__header">
        <h3 class="welcome-checklist__headline">Quick start guide</h3>
      </header>

      <div class="welcome-checklist__items">
        <Item
          class="welcome-checklist__item"
          index="1"
          :finished="state.lesson"
          name="Take a lesson"
          :description='`Pick up a lesson, for example <u>the first one</u>. Go throught it and hit the "Finish" button at the end.`'
          href="/lessons/introduction-to-sql-habit" />

        <Item
          class="welcome-checklist__item"
          index="2"
          :finished="state.query"
          name="Run an SQL query"
          :description="`Go to the <a href='/playground'>SQL Playground</a> or SQL Editor tab in any lesson or exercise and run your first SQL query.`"
          href="/playground" />

        <Item
          class="welcome-checklist__item"
          index="3"
          :finished="state.chatMessage"
          name="Ask AI Coach a question"
          :description="`Go to the <a href='/ai-coach'>AI Coach</a> page, click on the orange button in the bottom right corner or select any text in the lesson or exercise to ask AI Coach a question.`"
          href="/ai-coach" />

        <Item
          class="welcome-checklist__item"
          index="4"
          :finished="state.exercise"
          name="Solve a course exercise"
          :description="`Choose an exercise, for example <u>the first one</u>. You may want to finish a couple of lessons before or take a glimpse at the solution query.`"
          href="/exercises/inspecting-a-specific-user-record" />

        <Item
          class="welcome-checklist__item"
          index="5"
          :finished="state.practice"
          name="Start a Practice exercise"
          :description="`Practice is a collection of harder exercises. Just try solving <a href='/practice#1'>the first one</a> and experience the Practice format.`"
          href="/practice#how-many-users-do-we-have" />
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@images/font_awesome/x-light.svg";
import Item from "components/welcome-checklist/item";
import axios from "axios";
import { notify } from "modules/notify";
import mountContinueBanner from "controllers/continue-banner";
import { confettiBoom } from "modules/confetti-cannon";
import Sentry from "modules/sentry";

export default {
  name: "WelcomeChecklist",
  components: {
    CloseIcon,
    Item
  },
  props: {
    state: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    close() {
      this.$destroy();

      this.$el.parentNode.removeChild(this.$el);

      mountContinueBanner();
    },
    finish() {
      axios
        .patch("/api/welcome_checklists/finish", {}, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
          }
        })
        .then(response => {
          notify({
            message: "Quick start guide completed, yay! If you have any further questions always reach out to <a href='hello@sqlhabit.com'>hello@sqlhabit.com</a>, enjoy SQL Habit!",
            timeout: false
          });

          confettiBoom();

          this.close();
        })
        .catch(error => Sentry.captureException(error))
    },
    skip() {
      axios
        .patch("/api/welcome_checklists/skip", {}, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
          }
        })
        .then(response => {
          notify({
            message: "Quick start guide skipped. If you have any question always reach out to <a href='hello@sqlhabit.com'>hello@sqlhabit.com</a>",
            timeout: false
          });

          this.close();
        })
        .catch(error => Sentry.captureException(error))
    }
  },
  mounted() {
    if(Object.values(this.state).every(el => el === true)) {
      this.finish();
    }
  }
}
</script>

<style lang="scss">
.welcome-checklist {
  position: relative;
  max-width: $px720;
  overflow: hidden;

  border-radius: $px8;

  &:before {
    position: absolute;
    width: 300px;
    height: 300px;
    top: -150px;
    left: -100px;
    z-index: 1;

    border-radius: 150px;

    background-color: $yellow-9;

    opacity: 0.8;
    content: "";
  }

  &:after {
    position: absolute;
    width: 400px;
    height: 400px;
    bottom: -200px;
    right: -150px;
    z-index: 1;

    border-radius: $px24;

    background-color: $orange-9;

    opacity: 0.8;
    content: "";
    transform: rotate(-30deg);
  }

  &__container {
    padding: $px24;

    background-color: $grey-10;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $px24;
    position: relative;
    z-index: 2;
  }

  &__headline {
    margin: 0;
    padding: 0;
  }

  &__close-link {
    display: flex;
    align-items: center;
    position: absolute;
    top: $px12;
    right: $px16;

    @include font-small;
  }

  &__item {
    position: relative;
    z-index: 2;

    &:not(:last-child) {
      margin-bottom: $px20;
    }
  }
}
</style>
