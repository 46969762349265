import Vue from "modules/vue_twemoji";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import "./v-toast-styles.scss";

// https://github.com/ankurk91/vue-toast-notification/tree/v1.x
Vue.use(VueToast);

export const notify = ({ message, type = "success", timeout = true }) => {
  if (!message) {
    return;
  }

  // Drop Rails notifications just in case
  document.querySelectorAll(".notice").forEach(el => el.remove());

  Vue.$toast.open({
    message,
    type,
    position: "top-right",
    pauseOnHover: true,
    duration: timeout ? 10000 : 0,
    dismissible: true
  });
};
