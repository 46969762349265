<template>
  <div class="feedback-rating">
    <div class="feedback-rating__header">
      <div v-if="rating.value === 1">
        <SmileIcon :class="'feedback-rating__header-icon'" />
        What did you like about the {{ contentItemName.toLowerCase() }}?
      </div>

      <div v-else>
        <ListIcon :class="'feedback-rating__header-icon'" />
        What could be improved in this {{ contentItemName.toLowerCase() }}?
      </div>
    </div>

    <form action="" class="feedback-rating__form" @submit.prevent="submitFeedback">
      <textarea
        name="feedback"
        class="feedback-rating__input"
        placeholder="Share some feedback"
        v-model="rating.feedback">
      </textarea>

      <input
        type="submit"
        value="Send feedback"
        class="feedback-rating__submit">
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { notify } from "modules/notify";
import SmileIcon from "@images/font_awesome/comment-alt-smile-light.svg"
import ListIcon from "@images/font_awesome/clipboard-list-light.svg";
import Sentry from "modules/sentry";

export default {
  name: "FeedbackRating",
  computed: {
    contentItemName() {
      if(this.contentItem.contentType === "MockInterviews::Exercise") {
        return "exercise";
      } else {
        return this.contentItem.contentType;
      }
    }
  },
  methods: {
    submitFeedback: function(value) {
      let formData = new FormData();

      formData.append(
        "content_item_rating[feedback]",
        this.rating.feedback
      );

      axios.patch(this.rating.addFeedbackUrl(this.rating.id), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        notify({
          message: "Thank you for the feedback!"
        });

        this.$emit("feedbackRatingSaved");
      }).catch(function (error) {
        notify({
          message: "Smth went wrong",
          type: "error"
        });

        Sentry.captureException(error);
      });
    }
  },
  props: {
    contentItem: Object,
    rating: Object
  },
  components: {
    SmileIcon,
    ListIcon
  }
}
</script>

<style lang="scss">
.feedback-rating {
    width: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: $px8;
    }

    &__header-icon {
      width: $px24;
      height: $px24;
      margin-right: $px8;
    }

    &__form {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }

    &__input {
      width: 100%;
      height: $px144;
      margin-bottom: $px8;
      padding: $px8 $px16;

      border: $px1 solid $grey-7;
      border-radius: $px4;

      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      @include breakpoint($md) {
        width: $px496;
      }
    }

    &__submit {
      display: inline-block;
      padding: $px12 $px16;

      border: none;
      border-radius: $px4;

      background-color: $blue-6;

      color: $white;

      cursor: pointer;
      outline: none;

      &:hover {
        opacity: 0.7;
      }
    }

    @include breakpoint($md) {
      width: auto;
    }
  }
</style>
