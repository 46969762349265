<template>
  <div class="occupation-step pt-32">
    <div class="occupation-step__container">
      <div class="occupation-step__headline mb-24">
        <div class="flex align-center">
          <img
            class="occupation-step__avatar w-48 h-48 radius-50 mr-8"
            :src="require('@images/avatars/avatar.png')"
            alt="Anatoli, the author of SQL Habit">
          <span class="h2">What's your current role or field?</span>
        </div>
      </div>

      <div class="occupation-step__description">
        I'll use it to personalize your learning experience and make sure you're getting the most out of SQL Habit.
      </div>

      <div class="occupation-step__occupations">
        <a
          href="#"
          class="occupation-step__occupation btn btn--selectable"
          v-bind:class="{ 'btn--selected': occupationOptionClass(occupation) }"
          v-for="occupation in Object.keys(availableOccupations)"
          v-bind:key="occupation"
          v-on:click.prevent="toggleOccupation(occupation)">

          {{ availableOccupations[occupation] }}
        </a>

        <input
          type="text"
          placeholder="Other"
          name="other-occupation"
          class="occupation-step__occupation-input"
          data-value="other"
          v-model="otherValue"
          v-on:change="handleOtherInput">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      availableOccupations: {
        "marketing": "Marketing",
        "product-management": "Product Management",
        "bi": "Business Intelligence",
        "data-science": "Data Science",
        "software-engineering": "Software Engineering"
      },
      otherValue: null,
      values: {}
    }
  },
  props: ["occupations", "otherOccupation"],
  methods: {
    occupationOptionClass(occupation) {
      return this.values[occupation];
    },
    toggleOccupation(occupation) {
      this.$set(this.values, occupation, !this.values[occupation]);

      this.$emit("toggle-occupation", this.values);
    },
    handleOtherInput() {
      this.$emit("toggle-other-occupation", this.otherValue);
    }
  },
  mounted() {
    this.values = this.occupations;
    this.otherValue = this.otherOccupation;
  }
}
</script>

<style lang="scss">
.occupation-step {
  max-width: 720px;

  &__avatar {
    border: $px4 solid $blue-9;
  }

  &__description {
    margin-bottom: $px32;

    @include font-large;
  }

  &__occupations {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $px32;
  }

  &__occupation {
    margin-bottom: $px16;

    &:not(:last-child) {
      margin-right: $px16;
    }
  }

  &__occupation-input {
    margin-bottom: $px16;
    padding: $px8;

    outline: none;
    appearance: none;
    border: 0;
    border-bottom: $px1 solid $grey-9;

    color: $grey-1;

    &::placeholder {
      @include font-btn;
      color: $grey-7;
    }
  }
}
</style>
