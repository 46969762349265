<template>
  <div class="feature-list">
    <div class="feature-list__items">
      <div
        class="feature-list__item"
        :key="index"
        v-for="(feature, index) in $t('features')">
          <div
            class="feature-list__icon-container"
            :class="`feature-list__icon-container--${feature.iconBg}`">
            <component
              class="feature-list__icon"
              :is="require(`@images/font_awesome/${feature.icon}.svg`)" />
          </div>

          <div
            class="feature-list__description"
            v-html="$options.filters.md(feature.description)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureList",
  i18n: {
    messages: {
      en: {
        features: [
          {
            description: "Join the community of **%[usersCount] students** who are growing with SQL Habit.",
            icon: "users-light",
            iconBg: "blue-cyan"
          }, {
            description: "Become a Data Analysis and SQL expert with **%[totalCourseLessonsCount] hands-on exercises**.",
            icon: "chart-pie-light",
            iconBg: "yellow-green"
          }, {
            description: "Follow a fictional startup’s story and apply your skills through **real-world Marketing and Product case studies**.",
            icon: "mobile-light",
            iconBg: "green-grey"
          }, {
            description: "Practice live data analysis and creating dashboards with SQL Habit’s **interactive charting tool**.",
            icon: "chart-bar-light",
            iconBg: "blue-red"
          }, {
            description: "Prepare for interviews with **%[practiceExercisesCount] practical exercises in %[totalDatasetsCount] datasets**.",
            icon: "briefcase-light",
            iconBg: "grey-cyan"
          },
          {
            description: "Get answers and support from peers and experts on the **SQL Habit Community Forum**.",
            icon: "comments-light",
            iconBg: "orange-grey"
          }
        ]
      }
    }
  }
}
</script>

<style>

</style>
