<template>
  <table
    v-if="fields && result"
    class="query-result-table"
    :class="`query-result-table--col-${fields.length}`">
    <thead>
      <tr>
        <th
          :key="field"
          v-for="field in fields">
          <div class="query-result-table__th-wrapper">
            {{ field }}
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        :key="`row-${y}`"
        v-for="(row, y) in result">
        <td
          :key="`column-${y}-${x}`"
          v-for="(value, x) in row"
          >
          <div class="query-result-table__td-wrapper">
            <span
              class="query-result-table__null-value"
              v-if="value === null">NULL</span>
            <span
              v-else>{{ value }}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["fields", "result", "duration"]
}
</script>

<style lang="scss">
.query-result-table {
  $root: &;

  position: relative;
  width: 100%;

  border-collapse: collapse;

  &__th-wrapper {
    padding: $px4 $px8;
    max-width: $px320;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 29px; // https://stackoverflow.com/questions/7790222/css-display-table-min-height-not-working

    border-right: $px1 solid $grey-8;

    background-color: $grey-9;
  }

  th,
  td {
    padding: 0;
    margin: 0;
    border: 0;
  }

  tr {
    &:nth-child(even) {
      background-color: $grey-10;
    }
  }

  thead {
    tr {
      position: sticky;
      top: 0;
    }
  }

  &__td-wrapper {
    padding: $px4 $px8;
    max-width: $px320;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 29px; // https://stackoverflow.com/questions/7790222/css-display-table-min-height-not-working

    border-right: $px1 solid $grey-9;

    text-align: left;
  }

  tbody {
    td {
      &:only-of-type {
        #{$root}__td-wrapper {
          border-right: 0;
        }
      }

      &:last-child {
        #{$root}__td-wrapper {
          border-right: 0;
        }
      }
    }
  }

  th {
    &:only-of-type {
      #{$root}__th-wrapper {
        border-right: 0;
      }
    }

    &:last-child {
      #{$root}__th-wrapper {
        border-right: 0;
      }
    }
  }

  &__null-value {
    @include font-legal;
    color: $grey-8;
  }

  &--col-1,
  &--col-2,
  &--col-3,
  &--col-4,
  &--col-5,
  &--col-6 {
    #{$root}__th-wrapper {
      max-width: 100%;
    }

    #{$root}__td-wrapper {
      max-width: 100%;
    }
  }
}
</style>
