<template>
  <div
    class="practice-exercise-evaluation"
    :class="{
      'practice-exercise-evaluation--full-screen': fullScreen
    }">
    <h1
      class="practice-exercise-evaluation__name mb-16">
      {{ name }}
    </h1>

    <TabsMenu
      class="mb-32"
      :items="{
        explanation: 'Explanation',
        description: 'Description',
        sql_editor: 'SQL Editor',
        db_info: 'Database Info',
        sql_reference: 'SQL Reference',
      }"
      :activeItem="activeTab"
      @activeTab="handleTabChange">
    </TabsMenu>

    <Tab :active="activeTab === 'explanation'">
      <div
        ref="explanation"
        class="practice-exercise-evaluation__explanation-tab max-width-720">
        <div class="mb-72">
          <div
            class="practice-exercise-evaluation__status mb-12"
            :class="{
              'practice-exercise-evaluation__status--solved': solved,
              'practice-exercise-evaluation__status--unsolved': !solved
            }">
            <div
              v-if="solved"
              v-html="$options.filters.md(':white_check_mark: Nicely done, you\'ve nailed it! :clap:')" />

            <div
              v-else
              v-html="$options.filters.md(':x: O oh, looks like your solution doesn\'t produce the expected result set. Give it another try or take a look at the explanation below. :point_down:')" />
          </div>

          <div
            v-if="showWrongColumnNamesMessage"
            class="practice-exercise-evaluation__wrong-column-names mb-12">
            <span
              v-html="$options.filters.md(`:warning: It looks like you\'ve named your result set columns incorrectly. ${expectedColumnNamesSentence}`)" />
          </div>

          <div class="relative js-with-selected-text-menu js-content-container">
            <h2 class="mb-16">Explanation</h2>

            <div
              class="practice-exercise-evaluation__explanation"
              :class="{
                'practice-exercise-evaluation__explanation--revealed': showExplanation
              }"
              v-html="explanation" />

            <div
              v-if="!showExplanation"
              class="practice-exercise-evaluation__explanation-fadeaway">
              <div class="practice-exercise-evaluation__reveal-explanation-button">
                <button
                  @click.prevent="explanationVisible = true"
                  class="btn"
                >Reveal full explanation</button>
              </div>
            </div>

            <div class="selected-text-menu-container">
              <div class="js-selected-text-menu"></div>
            </div>

            <div class="selected-text-menu-forms">
              <div class="js-selected-text-menu-forms"></div>
            </div>
          </div>

          <div
            class="practice-exercise-evaluation__user-solution">
            <h2 class="">Your solution</h2>

            <pre class="mt-0">
              <code
                class="language-pgsql hljs"
                v-html="solutionQuery" />
            </pre>
          </div>
        </div>
      </div>

      <div class="practice-exercise-evaluation__actions">
        <a class="btn" :href="restartUrl">
          <span class="mr-4" v-html="$options.filters.md(':triumph:')" />
          Start again
        </a>

        <div>
          <ContentItemRating :inputRating="inputRating" />
        </div>
      </div>
    </Tab>

    <Tab :active="activeTab === 'description'">
      <div class="practice-exercise-evaluation__description-tab max-width-720">
        <div
          class="practice-exercise-evaluation__description"
          v-html="description" />

        <div
          v-if="exercise.table_and_column_highlights && !exercise.has_schema_visualization_link"
          class="practice-exercise-evaluation__dataset-highlight-url">
          <span v-html="$options.filters.md(`:mag: You can`)" />
          <a
            :href="`https://sqlhabit.github.io/sql_schema_visualizer/databases/${exercise.dataset_slug}?highlights=${exercise.table_and_column_highlights}`"
            target="_blank">lookup database schema visualization here.</a>
        </div>
      </div>
    </Tab>

    <Tab :active="activeTab === 'sql_editor'">
      <div class="practice-exercise-evaluation__workspace max-width-960">
        <SQLEditor
          ref="sqlEditor"
          :interviewItem="exercise"
          :formLock="formLock"
          v-on:query-changed="queryChanged"
          :initialQuery="solutionQuery" />
      </div>
    </Tab>

    <Tab :active="activeTab === 'db_info'">
      <DatabaseDocumentation
        :showDatasetMenu="false"
        :forceDatasetId="datasetId" />
    </Tab>

    <Tab :active="activeTab === 'sql_reference'">
      <SqlReference />
    </Tab>
  </div>
</template>

<script>
import SQLEditor from "components/mock-interview/sql-editor";
import TabsMenu from "components/tabs-menu";
import Tab from "components/tab";
import DatabaseDocumentation from "components/database-documentation";
import SqlReference from "components/sql-reference";
import ContentItemRating from "components/content-item-rating";
import hljs from "highlight.js/lib/core";
import pgsql from "highlight.js/lib/languages/pgsql";
import { loadMathjax } from "controllers/mathjax";
import customStore from "./custom-store";
import { highlightChildren } from "modules/code-highlight";
import { getCookie, deleteCookie } from "modules/cookie";
import { confettiBoom } from "modules/confetti-cannon";
import { initSelectedTextMenu } from "controllers/selected-text-menu";

import "highlight.js/styles/vs.css";

export default {
  name: "PracticeExerciseEvaluation",
  components: {
    SQLEditor,
    TabsMenu,
    Tab,
    DatabaseDocumentation,
    SqlReference,
    ContentItemRating
  },
  data() {
    return {
      activeTab: "explanation",
      query: null,
      formLock: false,
      customStore: customStore,
      exercise: window.state.exercise,
      explanation: window.state.explanation,
      mockIntervewSlug: window.state.mockInterviewId,
      solutionQuery: decodeURIComponent(window.state.solutionQuery.replace(/\+/g, " ")),
      solved: window.state.solved,
      fullScreen: false,
      restartUrl: window.state.restartUrl,
      explanationVisible: false,
      contentItemRating: window.state.contentItemRating,
      canSeeFullExplanation: window.state.canSeeFullExplanation,
      userQueryResultSetColumns: window.state.userQueryResultSetColumns,
      solutionQueryResultSetColumns: window.state.solutionQueryResultSetColumns
    }
  },
  computed: {
    name() {
      return this.exercise.name;
    },
    description() {
      return this.exercise.description;
    },
    datasetId() {
      return this.exercise.dataset_id;
    },
    showWrongColumnNamesMessage() {
      if (this.userQueryResultSetColumns.length !== this.solutionQueryResultSetColumns.length) {
        return true;
      }

      const sortedUserColumns = [...this.userQueryResultSetColumns].sort();
      const sortedSolutionColumns = [...this.solutionQueryResultSetColumns].sort();

      for (let i = 0; i < sortedSolutionColumns.length; i++) {
        if (sortedUserColumns[i] !== sortedSolutionColumns[i]) {
          return true;
        }
      }

      return false;
    },
    expectedColumnNamesSentence() {
      let sentence = "Expected column name";

      if (this.solutionQueryResultSetColumns.length === 1) {
        sentence += " is: ";
      } else {
        sentence += "s are: ";
      }

      sentence += `**${this.solutionQueryResultSetColumns.join(", ")}**.`;

      return sentence;
    },
    showExplanation() {
      if (!this.canSeeFullExplanation) {
        return true;
      }

      if (this.solved) {
        return true;
      }

      return this.explanationVisible;
    },
    inputRating() {
      return {
        id: this.contentItemRating.id,
        value: this.contentItemRating.value,
        type: "5star",
        createUrl: this.contentItemRating.create_url,
        persisted: this.contentItemRating.persisted,
        feedbackPresent: !!this.contentItemRating.feedback,
        addFeedbackUrl: (ratingId) => {
          return `/content_item_ratings/${ratingId}/add_feedback.json`
        }
      }
    }
  },
  methods: {
    handleTabChange(newTab) {
      this.activeTab = newTab;

      if(newTab === "explanation" && window.state.runQueryScrollY) {
        this.$nextTick(() => {
          window.scrollTo(0, window.state.runQueryScrollY);
          window.state.runQueryScrollY = null;
        });
      }

      if(this.$refs.query) {
        this.$refs.query.querySelectorAll("pre code").forEach((block) => {
          hljs.highlightBlock(block);
        });
      }
    },
    queryChanged(query) {
      this.query = query;
    },
  },
  mounted() {
    hljs.registerLanguage("pgsql", pgsql);

    hljs.configure({
      ignoreUnescapedHTML: false,
      throwUnescapedHTML: false
    });

    this.$nextTick(() => {
      loadMathjax();

      highlightChildren(this.$refs.explanation)
    });

    if(this.solved && this.mockIntervewSlug === getCookie("sqlh_practice_confetti")) {
      confettiBoom();
      deleteCookie("sqlh_practice_confetti");
    }

    this.$refs.explanation.addEventListener("click", (e) => {
      if(e.target && e.target.matches(".js-run-query-link") && window.state.editor) {
        e.preventDefault();

        let $runQueryLink = e.target;

        this.$refs.sqlEditor.clearQuery();

        const linkQuery = decodeURIComponent($runQueryLink.getAttribute("data-query"));
        window.state.editor.getSession().setValue(linkQuery);

        window.state.runQueryScrollY = window.scrollY;

        this.activeTab = "sql_editor";

        this.$refs.sqlEditor.submitQuery();

        if(window.scrollTo) {
          window.scrollTo(0, 0);
        }
      }
    });

    initSelectedTextMenu();
  }
}
</script>

<style lang="scss">
.practice-exercise-evaluation {
  $root: &;

  &__name {
    display: flex;
    align-items: baseline;

    @include h1;

    .emoji {
      height: 0.7em;
      width: 0.7em;
      margin-right: $px12;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__submit {
    margin-right: $px24;
  }

  &__description {
    margin-bottom: $px24;

    p:first-child {
      margin-top: 0;
    }

    .table {
      overflow-x: auto;
    }

    table {
      border: $px1 solid $grey-7;
      border-radius: $px4;
      border-collapse: collapse;

      tr:first-child {
        background-color: $grey-7;

        font-weight: 700;
      }

      td {
        white-space: nowrap;
      }

      tr {
        &:nth-child(even) {
          background-color: $grey-10;
        }
      }

      th,
      td {
        padding: $px4 $px8;

        text-align: left;
      }
    }
  }

  &__left-column {
    width: 45%;
    padding-right: $px32;
  }

  &__sql-editor {
    width: 55%;
  }

  &__skip {
    @include font-link;

    &--disabled {
      color: $grey-7;
    }
  }

  &__status {
    padding: $px12 $px16;

    border-radius: $px8;

    &--solved {
      background-color: $green-9;
    }

    &--unsolved {
      background-color: $yellow-9;
    }
  }

  &__wrong-column-names {
    padding: $px12 $px16;

    border-radius: $px8;

    background-color: $yellow-9;
  }

  &__explanation {
    max-height: $px96;
    overflow: hidden;

    &--revealed {
      max-height: none;
      overflow: auto;
    }
  }

  &__user-solution {
    line-height: 2;

    pre {
      white-space: pre-line;
    }

    code {
      white-space: pre;
    }
  }

  &__actions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__explanation-fadeaway {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0;

    background-image: linear-gradient(to bottom, rgba($white, 0) 0%, $white 100%);;
  }

  &__reveal-explanation-button {
    position: absolute;
    width: 80%;
    bottom: $px8;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}
</style>
