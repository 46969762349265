<template>
  <div
    ref="container"
    class="mock-interview-sql-editor">
    <div class="mock-interview-sql-editor__query-editor">
    </div>

    <div class="mock-interview-sql-editor__actions">
      <button
        class="mock-interview-sql-editor__run-query btn btn--sm"
        :disabled="formSubmitted || formLock"
        @click.prevent="submitQuery">

        <span v-if="formSubmitted">
          Running query...
        </span>

        <span v-else-if="formLock">
          Submitting your query...
        </span>

        <span v-else>
          RUN QUERY
        </span>
      </button>

      <a
        class="mock-interview-sql-editor__database-name"
        target="_blank"
        href="https://www.postgresql.org/docs/11/sql-syntax.html">
        PostgreSQL
        <PostgresIcon
          :class="'mock-interview-sql-editor__database-name-icon'" />
      </a>
    </div>

    <div
      class="mock-interview-sql-editor__result"
      v-if="queryResult || queryErrors">

      <QueryResultWrapper
        :fields="queryFields"
        :result="queryResult"
        :duration="queryDuration"
        :errors="queryErrors" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-pgsql";
import "ace-builds/src-noconflict/theme-sqlserver";
import { notify } from "modules/notify";
import QueryResultWrapper from "components/shared/query-result-wrapper";
import Sentry from "modules/sentry";

import PostgresIcon from "@images/font_awesome/postgresql-light.svg";

import PGSQL_KEYWORDS from "modules/ace-pgsql-keywords";

export default {
  data() {
    return {
      query: "",
      formSubmitted: false,
      queryFields: null,
      queryResult: null,
      queryDuration: null,
      queryErrors: null,
      editor: null
    }
  },
  props: {
    interviewItem: {
      type: Object
    },
    formLock: {
      type: Boolean,
      default: false
    },
    initialQuery: {
      type: String
    }
  },
  methods: {
    clearQuery() {
      this.editor.getSession().setValue("");
      this.queryResult = null;
      this.queryDuration = null;
    },
    submitQuery() {
      if(this.formLock) {
        return;
      }

      if(this.query.length === 0) {
        return;
      }

      if(this.formSubmitted) {
        return;
      } else {
        this.formSubmitted = true;
      }

      let formData = new FormData();

      formData.append("result_format", "json");

      formData.append("query[content_id]", this.interviewItem.id);
      formData.append("query[content_type]", "MockInterview::Exercise");

      formData.append("query[body]", this.query);
      formData.append("query[dataset_id]", this.interviewItem.dataset_id);

      axios.post("/queries.json", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        this.queryFields = response.data.fields;
        this.queryResult = response.data.result;
        this.queryDuration = response.data.duration;
        this.queryErrors = response.data.errors;
      }).catch(function(error) {
        notify({
          message: error.response.data.errors,
          type: "error"
        });

        if(this.queryResult) {
          this.queryResult = null;
        }

        Sentry.captureException(error);
      }).finally(() => {
        setTimeout(() => {
          this.formSubmitted = false;
        }, 1000);
      });
    },
    getAutocompletePopup() {
      // https://github.com/ajaxorg/ace/issues/3199
      if (this.editor.completer) {
        this.editor.completer.destroy();
      }

      const Autocomplete = ace.require("ace/autocomplete").Autocomplete;
      this.editor.completer = new Autocomplete();
      this.editor.completer.editor = this.editor;
      this.editor.completer.$init();

      return this.editor.completer.popup.container;
    },
    handleFullscreenChange() {
      const popup = this.getAutocompletePopup();

      if (!popup) {
        return;
      }

      if (document.fullscreenElement) {
        this.$refs.container.appendChild(popup);
      } else {
        document.body.appendChild(popup);
      }
    }
  },
  mounted() {
    let $editorContainer = document.querySelector(".mock-interview-sql-editor__query-editor");

    this.editor = ace.edit($editorContainer);

    this.editor.setOptions({
      fontFamily: "Hack",
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: false,
      highlightActiveLine: false,
    });

    if (!("state" in window)) {
      window.state = {};
    }

    window.state.editor = this.editor;

    const schemaAutoCompleter = {
      getCompletions: (editor, session, pos, prefix, callback) => {
        if (prefix.length === 0) {
          callback(null, []); return
        }

        let datasetId = this.interviewItem.dataset_id;

        axios.get(
          `/queries/autocomplete.json?q=${prefix}&dataset_id=${datasetId}`
        ).then(response => {
          callback(null, response.data.words.map(function(ea) {
            return {
              name: ea.word,
              value: ea.word,
              score: ea.score,
              meta: ea.meta
            }
          }));
        }).catch(function(error) {
          Sentry.captureException(error);
        })
      }
    };

    const sqlAutoCompleter = {
      getCompletions: (editor, session, pos, prefix, callback) => {
        if (prefix.length === 0) {
          callback(null, []); return
        }

        let matchedKeywords = Object.keys(PGSQL_KEYWORDS).reduce((a, key) => {
          let re = new RegExp(prefix, "i");

          if(key.match(re)) {
            if(typeof(PGSQL_KEYWORDS[key]) === "string") {
              a.push({
                name: key,
                value: key,
                meta: PGSQL_KEYWORDS[key]
              });
            } else if(typeof(PGSQL_KEYWORDS[key]) === "object") {
              let params = {
                name: key,
                caption: PGSQL_KEYWORDS[key].caption || key,
                value: PGSQL_KEYWORDS[key].value,
                meta: PGSQL_KEYWORDS[key].type
              };

              if(PGSQL_KEYWORDS[key].completer) {
                params.completer = PGSQL_KEYWORDS[key].completer;
              }

              a.push(params);
            }
          }

          return a;
        }, []);

        callback(null, matchedKeywords);
      }
    };

    this.editor.completers = [
      schemaAutoCompleter,
      sqlAutoCompleter
    ];

    this.editor.setTheme("ace/theme/sqlserver");
    this.editor.setShowPrintMargin(false);
    this.editor.session.setMode("ace/mode/pgsql");

    if(screen.width <= 768) {
      this.editor.setFontSize("18px");
    }

    let newQuery;
    this.editor.getSession().on("change", () => {
      newQuery = this.editor.getSession().getValue();
      this.query = newQuery;

      this.$emit("query-changed", newQuery);
    });

    if(this.initialQuery) {
      const initialQuery = this.initialQuery;
      this.editor.getSession().setValue(initialQuery);

      const queryLOC = initialQuery.split("\n").length;

      this.editor.moveCursorTo(queryLOC, 0);
    }

    this.editor.focus();

    document.addEventListener("keydown", e => {
      if (!((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode == 13)) {
        return;
      }

      this.submitQuery();
    });

    document.addEventListener("fullscreenchange", this.handleFullscreenChange)
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.handleFullscreenChange)
  },
  components: {
    QueryResultWrapper,
    PostgresIcon
  }
}
</script>

<style lang="scss">
.mock-interview-sql-editor {
  &__query-editor {
    width: 100%;
    height: 250px;
    margin-bottom: $px8;

    border: $px1 solid $grey-9;
    border-radius: $px4;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__database-name {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: $px24;

    @include font-small;
    color: $grey-5;
    text-decoration: none;
  }

  &__database-name-icon {
    width: $px24;
    height: $px24;
    margin-left: $px4;
  }
}
</style>
