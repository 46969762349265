<template>
  <div class="query-result-wrapper">
    <header class="query-result-wrapper__header">
      <h2
        v-if="showHeadline"
        class="query-result-wrapper__headline">
        Query result
      </h2>

      <div class="query-result-wrapper__duration" v-if="localDuration">
        {{ resultLength }} {{ $tc("row", resultLength) }}, {{ formattedDuration }} ms
      </div>
    </header>

    <div
      class="query-result-wrapper__result"
      v-if="errors">
      <QueryError
        :errors="errors" />
    </div>
    <div
      class="query-result-wrapper__result"
      :style="`max-height: ${maxHeight}px;`"
      v-else>
      <QueryResult
        :fields="fields"
        :result="result"
        :duration="duration" />
    </div>
  </div>
</template>

<script>
import QueryResult from "components/shared/query-result";
import QueryError from "components/shared/query-error";

export default {
  name: "QueryResultWrapper",
  components: {
    QueryResult,
    QueryError
  },
  data() {
    return {
      localResult: "",
      localDuration: null
    }
  },
  props: {
    showHeadline: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: Number,
      default: 500
    },
    fields: {
      type: Array,
      default: null
    },
    result: {
      type: Array,
      default: []
    },
    duration: {
      type: Number,
      default: 0
    },
    errors: {
      type: String,
      default: null
    }
  },
  computed: {
    formattedDuration() {
      return Math.round(this.localDuration);
    },
    resultLength() {
      if(this.result && this.result.length) {
        return this.result.length;
      } else {
        return 0;
      }
    }
  },
  watch: {
    result(newVal, oldVal) {
      this.localResult = newVal;
    },
    duration(newVal, oldVal) {
      this.localDuration = Math.round(newVal, 2);
    }
  },
  mounted() {
    this.localResult = this.result;
    this.localDuration = Math.round(this.duration, 2);
  },
  i18n: {
    messages: {
      en: {
        row: "rows | row | rows"
      }
    }
  }
}
</script>

<style lang="scss">
.query-result-wrapper {
  position: relative;

  &__header {
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__headline {
    margin-bottom: $px8;
  }

  &__result {
    width: 100%;
    overflow: auto;

    border-radius: $px4;
    border: $px1 solid $grey-9;

    font-size: 14px;

    &::-webkit-scrollbar {
      width: $px8;
      height: $px8;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: $px4;

    	background-color: $grey-8;
    }

    &--scrolled {
      table {
        thead {
          th {
          }
        }
      }
    }
  }

  &__duration {
    position: absolute;
    top: -$px4;
    right: 0;

    @include font-small;
    text-align: right;
    color: $grey-6;

    transform: translate(0, -100%);
  }
}
</style>
