<template>
  <button
    class="selectable-card"
    v-bind:class="{ 'selectable-card--selected': selected }"
    v-bind:key="value"
    v-on:click.prevent="toggleValue(value)">

    <CheckedIcon
      v-if="selected"
      class="selectable-card__checked-icon" />

    <div
      class="selectable-card__emoji"
      v-html="$options.filters.md(emoji)" />

    <div
      class="selectable-card__name"
      v-html="$options.filters.md(name)" />

    <div
      class="selectable-card__description"
      v-html="$options.filters.md(description)" />
  </button>
</template>

<script>
import CheckedIcon from "@images/font_awesome/check-circle-solid.svg";

export default {
  name: "SelectableCard",
  components: {
    CheckedIcon
  },
  props: ["selected", "value", "emoji", "name", "description"],
  data() {
    return {
      selected: false
    }
  },
  methods: {
    toggleValue() {
      this.selected = !this.selected;

      this.$emit("selected", this.value);
    }
  }
}
</script>

<style lang="scss">
.selectable-card {
  padding: $px16;
  width: 100%;
  position: relative;

  border: $px2 solid $grey-8;
  border-radius: $px12;

  background-color: $white;

  text-align: left;

  transition: border-color .3s, background-color .3s;
  cursor: pointer;

  &:hover {
    border-color: $grey-7;

    background-color: $grey-10;
  }

  &--selected {
    border-color: $blue-8;

    &:hover {
      border-color: $blue-8;
    }
  }

  @include breakpoint($sm) {
    width: 33%;
  }

  &__emoji {
    width: 44px;
    height: 44px;
    padding: $px12;
    margin-bottom: $px24;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-shrink: 0;

    font-size: $px20;
    line-height: 1;

    border-radius: 50%;

    background-color: $grey-10;

    appearance: none;
  }

  &__name {
    margin-bottom: $px8;

    font-weight: 600;
    letter-spacing: 0.65px;
  }

  &__checked-icon {
    position: absolute;
    top: $px8;
    right: $px8;
    width: $px24;
    height: $px24;

    color: $blue-7;
  }
}
</style>
