<template>
  <div class="tabs-menu">
    <div class="tabs-menu__inner">
      <div
        v-for="(name, id) in items"
        :key="id"
        class="tabs-menu__button"
        :class="{
          'tabs-menu__button--active': activeItem === id
        }"
        :data-item="id"
        @click="$emit('activeTab', id)">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabsMenu",
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    activeItem: {
      type: String
    }
  }

};
</script>

<style lang="scss">
</style>
