<template>
  <div
    class="bookmark animation"
    v-if="loaded"
    @click.prevent="toggleBookmark"
    :class="{
      'animation--flip-in-x': disabled
    }">
    <a
      href="#"
      class="bookmark__link">
      <span v-if="showCopy">Bookmark</span>
      <BookmarkFull
        v-if="active"
        :class="`w-${iconSize} h-${iconSize}`" />
      <BookmarkEmpty
        v-else
        :class="`w-${iconSize} h-${iconSize}`"/>
    </a>
  </div>
</template>

<script>
import axios from "axios";
import BookmarkEmpty from "@images/font_awesome/bookmark-light.svg";
import BookmarkFull from "@images/font_awesome/bookmark-solid.svg";
import { notify } from "modules/notify";
import log from "modules/log";

export default {
  name: "Bookmark",
  components: {
    BookmarkEmpty,
    BookmarkFull
  },
  props: {
    contentType: {
      type: String,
      default: null
    },
    contentSlug: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 16
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    initActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loaded: false,
      active: false,
      disabled: true
    }
  },
  computed: {
    showCopy() {
      return !this.iconOnly;
    }
  },
  methods: {
    toggleBookmark() {
      if(this.disabled) {
        return;
      } else {
        this.disabled = true;
      }

      let formData = new FormData();

      formData.append(
        "content_type",
        this.contentType
      );

      formData.append(
        "content_slug",
        this.contentSlug
      );

      axios.patch("/api/bookmarks/toggle.json", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        this.loaded = true;
        this.active = response.data.active;
      }).catch((error) => {
        log(error);

        notify({
          message: "Oops, something went wrong. Please, try again or ping support@sqlhabit.com",
          type: "error"
        });
      }).finally(() => {
        setTimeout(() => {
          this.disabled = false;
        }, 750);
      });
    }
  },
  mounted() {
    if(this.initActive) {
      this.active = true;
      this.loaded = true;
      this.disabled = false;
    } else {
      axios.get("/api/bookmark.json", {
        params: {
          content_type: this.contentType,
          content_slug: this.contentSlug
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        this.loaded = true;
        this.active = response.data.active;
      }).catch((error) => {
        log(error);

        notify({
          message: "Oops, something went wrong. Please, try again or ping support@sqlhabit.com",
          type: "error"
        });
      }).finally(() => {
        this.disabled = false;
      });
    }
  }
}
</script>
