<template>
  <div class="subscription-pricing-plan" :class="bemModifiers">
    <div
      v-if="yearlySavingPercentage"
      class="subscription-pricing-plan__yearly-savings">
      SAVE {{ yearlySavingPercentage }}%
    </div>

    <div class="subscription-pricing-plan__name mb-32">
      {{ $t(`planName.${subscriptionPlan.billingPeriod}`).toUpperCase() }}
    </div>

    <div
      v-if="subscriptionPlan.billingPeriod !== 'free'"
      class="subscription-pricing-plan__price-per-month flex align-baseline mb-32">
      <div class="flex items-center mr-4">
        <div class="subscription-pricing-plan__final-price">
          {{ formattedFinalPrice }}
        </div>
      </div>

      <div class="subscription-pricing-plan__per-month flex">
        /{{ $t(`perBillingPeriod.${subscriptionPlan.billingPeriod}`) }}
      </div>
    </div>

    <div class="subscription-pricing-plan__plan-description mb-32">
      <div
        v-for="(feature, index) in planFeatures"
        :key="index"
        class="subscription-pricing-plan__plan-description-line"
      >
        <CheckMark class="subscription-pricing-plan__plan-description-check-mark" />
        <span v-html="$options.filters.md(feature)"></span>
      </div>
      <div
        v-if="planDescription"
        class=""
        v-html="$options.filters.md(planDescription)">
      </div>
    </div>

    <a
      class="mb-8 btn btn--fat btn--full-width"
      :class="{
        'js-open-signup-popup': isGuest
      }"
      :data-after-auth="subscriptionPlan.billingPeriod !== 'free' ? ctaUrl : null"
      :href="ctaUrl">
      Get started
    </a>

    <div
      v-if="showOriginalStrikedPrice"
      class="subscription-pricing-plan__billing-price">
      <span
        class="mr-4 subscription-pricing-plan__billing-price--striked">
        {{ formattedOriginalPrice }}
      </span>
      {{ subscriptionPlan.finalPriceFormatted }} billed {{ $t(`billingPeriod.${subscriptionPlan.billingPeriod}`) }}
    </div>
  </div>
</template>

<script>
import currency from "currency.js";
import { mapGetters } from "vuex";
import { unindent } from "helpers/unindent";
import CheckMark from "@images/font_awesome/check-light.svg";

const currencyFormatter = {
  usd: (value) => {
    return currency(value, {
      symbol: "$",
      precision: 2,
      fromCents: true,
      separator: ",",
      decimal: ".",
      pattern: "!#"
    }).format().replace(".00", "");
  },
  eur: (value) => {
    return currency(value, {
      symbol: "€",
      precision: 2,
      fromCents: true,
      separator: ".",
      decimal: ",",
      pattern: "#!"
    }).format().replace(",00", "");
  }
}

export default {
  components: {
    CheckMark
  },
  computed: {
    ...mapGetters("user", ["loggedIn", "isGuest"]),
    ctaUrl() {
      if (this.subscriptionPlan.billingPeriod === "free") {
        return "/signup";
      } else {
        return this.subscriptionPlan.purchaseHref;
      }
    },
    bemModifiers() {
      return this.modifiers.map(m => {
        return `subscription-pricing-plan--${m}`
      }).join(" ");
    },
    planFeatures() {
      if (!this.$te(`planFeatures.${this.subscriptionPlan.billingPeriod}`)) {
        return [];
      }

      return this.$t(`planFeatures.${this.subscriptionPlan.billingPeriod}`);
    },
    planDescription() {
      if (!this.$te(`planDescription.${this.subscriptionPlan.billingPeriod}`)) {
        return;
      }

      const rawDescription = this.$t(`planDescription.${this.subscriptionPlan.billingPeriod}`, {
        yearlySavingPercentage: this.yearlySavingPercentage
      });

      if (!rawDescription) {
        return;
      }

      return unindent(rawDescription);
    },
    yearlySavingPercentage() {
      if (!this.finalMonthlyPrice) {
        return null;
      }

      if (this.subscriptionPlan.billingPeriod !== "year") {
        return null;
      }

      const monthlyPricePerYear = 12 * this.finalMonthlyPrice;
      const yearlyPrice = this.subscriptionPlan.finalPrice;

      return Math.floor(100.0 * (monthlyPricePerYear - yearlyPrice) / monthlyPricePerYear);
    },
    formattedOriginalPrice() {
      if (this.subscriptionPlan.billingPeriod === "year") {
        const currency = this.subscriptionPlan.currency;
        const monthlyPricePerYear = 12 * this.originalMonthlyPrice;

        return currencyFormatter[currency](monthlyPricePerYear);
      } else {
        return this.subscriptionPlan.originalPriceFormatted;
      }
    },
    formattedFinalPrice() {
      if (this.subscriptionPlan.billingPeriod === 'year') {
        const currency = this.subscriptionPlan.currency;
        const yearlyPricePerMonth = this.subscriptionPlan.finalPrice / 12.0;

        return currencyFormatter[currency](yearlyPricePerMonth);
      } else {
        return this.subscriptionPlan.finalPriceFormatted;
      }
    },
    showOriginalStrikedPrice() {
      return this.subscriptionPlan.hasDiscount || this.subscriptionPlan.billingPeriod === "year";
    }
  },
  props: {
    subscriptionPlan: {
      type: Object,
      default: () => {
        return {};
      }
    },
    modifiers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    finalMonthlyPrice: {
      type: Number,
      default: null
    },
    originalMonthlyPrice: {
      type: Number,
      default: null
    }
  },
  i18n: {
    messages: {
      en: {
        planName: {
          free: "Free",
          day: "Daily",
          week: "Weekly",
          month: "Monthly",
          year: "Yearly"
        },
        perBillingPeriod: {
          day: "day",
          week: "week",
          month: "mo",
          year: "mo"
        },
        planFeatures: {
          free: [
            "**AI Coach** with small LLM model",
            "**%[freeLessonsCount]** lessons and exercises",
            "**%[freePracticeExercisesCount]** Mock Interview exercises",
            "**Unlimited access** to Community Forum"
          ],
          month: [
            "**AI Coach** with powerful LLM model",
            "**%[totalCourseLessonsCount]** course lessons and exercises",
            "**%[practiceExercisesCount]** extra practice exercises",
            "**Unlimited access** to Community Forum",
            "**Unlimited access** to Chart Tool",
            "Verified **LinkedIn certificate**",
            "**Private Telegram group** with the author and your fellow course participants"
          ]
        },
        planDescription: {
          year: "The yearly plan includes all features of the monthly plan, **saves you {yearlySavingPercentage}%**, and is perfect for completing the SQL Habit Course, preparing for interviews, and building a strong data portfolio on GitHub."
        },
        billingPeriod: {
          day: "daily",
          week: "weekly",
          month: "monthly",
          year: "once a year"
        },
      }
    }
  }
}
</script>

<style lang="scss">
.subscription-pricing-plan {
  $root: &;

  position: relative;
  padding: $px24;

  border-radius: $px8;

  background-color: $grey-10;

  &__name {
    @include h3;
    font-weight: 700;
  }

  &__final-price {
    font-size: $px32;
    line-height: $px32;
    font-weight: 700;
  }

  &__per-month {
    margin-top: auto;

    font-size: $px20;
    line-height: 1;
  }

  &__billing-price {
    margin-top: $px8;

    @include font-small;
    text-align: center;

    &--striked {
      color: $grey-2;
      text-decoration: line-through;
    }
  }

  &__yearly-savings {
    position: absolute;
    right: $px16;
    top: 0;
    transform: translateY(-50%);
    padding: $px4 $px12;

    border-radius: $px16;

    background-color: $purple-5;

    color: $white;
    font-size: 15px;
    font-weight: 700;
  }

  &__plan-description-line {
    display: flex;
    align-items: flex-start;
    gap: $px8;
    margin-bottom: $px8;
  }

  &__plan-description-check-mark {
    flex-shrink: 0;
    width: $px16;
    height: $px16;
    margin-top: $px4;

    color: $grey-5;
  }
}
</style>
