const getters = {
  isGuest(state) {
    return state.isGuest;
  },
  loggedIn(state) {
    return state.authToken && !state.isGuest;
  },
  authenticated(state) {
    return !!state.authToken;
  },
  authToken(state) {
    return state.authToken;
  },
  username(state) {
    return state.username;
  },
  firstName(state) {
    return state.firstName;
  },
  userHandle(state) {
    return state.firstName || state.username;
  },
  segment(state) {
    return state.segment;
  },
  isCustomer(state) {
    return state.segment === "customer";
  },
  isFreeUser(state) {
    return state.segment === "free";
  }
};

export default getters;
