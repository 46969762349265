<template>
  <div class="receipt-customer-info">
    <div v-if="state === 'display'">
      <div class="receipt-customer-info__customer-info print--whitespace-pre">{{ info }}</div>

      <a
        class="receipt-customer-info__edit-link no-print"
        href="#"
        @click.prevent="editInfo">
        <EditIcon /> Edit
      </a>
    </div>

    <div v-if="state === 'edit'">
      <textarea
        v-model="info"
        class="receipt-customer-info__textarea">
      </textarea>

      <button
        class="btn btn--sm"
        @click.prevent="saveInfo"
        :class="{
          'btn--disabled': formDisabled
        }">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import EditIcon from "@images/font_awesome/edit-solid.svg";
import axios from "axios";
import { notify } from "modules/notify";
import Sentry from "modules/sentry";

export default {
  name: "ReceiptCustomerInfo",
  components: {
    EditIcon
  },
  data() {
    return {
      info: "",
      state: "display",
      formDisabled: false
    }
  },
  props: {
    infoInput: {
      type: String,
      default: null
    },
    updateReceiptCustomerEndpoint: {
      type: String,
      default: null
    }
  },
  methods: {
    editInfo() {
      this.state = "edit";
    },
    saveInfo() {
      if(this.formDisabled) {
        return;
      }

      this.formDisabled = true;

      let formData = new FormData();

      formData.append("purchase[receipt_customer]", this.info);

      axios.patch(this.updateReceiptCustomerEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        notify({
          message: "Invoice was updated."
        });

        this.state = "display";
      }).catch(function (error) {
        notify({
          message: "Something went wrong, please contact customer support at support@sqlhabit.com",
          type: "error"
        });

        Sentry.captureException(error);
      }).finally(() => {
        this.formDisabled = false;
      });
    }
  },
  mounted() {
    this.info = this.infoInput;
  }
}
</script>

.<style lang="scss">
.receipt-customer-info {
  &__textarea {
    display: block;
    width: 100%;
    margin-bottom: $px8;
    min-height: $px144;
    padding: $px8;

    line-height: 1.5;
  }

  &__customer-info {
    white-space: pre;
  }

  &__edit-link {
    display: flex;
    align-items: center;

    font-weight: 800;

    svg {
      width: $px20;
      height: $px20;
    }
  }
}
</style>
