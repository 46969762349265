import Vue from "modules/vue_twemoji";
import PracticeExerciseEvaluation from "components/practice/evaluation";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const $container = document.querySelector(".js-practice-exercise-explanation");

if($container) {
  new Vue({
    i18n: new VueI18n({
      locale: "en"
    }),
    el: $container,
    render: h => h(PracticeExerciseEvaluation)
  });
}
