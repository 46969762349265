import { render, staticRenderFns } from "./confirmation-popup.vue?vue&type=template&id=3996b51c"
import script from "./confirmation-popup.vue?vue&type=script&lang=js"
export * from "./confirmation-popup.vue?vue&type=script&lang=js"
import style0 from "./confirmation-popup.vue?vue&type=style&index=0&id=3996b51c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports