<template>
  <div class="selected-text-ai-assistant-form">
    <CloseIcon class="selected-text-ai-assistant-form__close" @click="close" />

    <div class="selected-text-ai-assistant-form__container">
      <div class="selected-text-ai-assistant-form__text-fragment">
        ...{{ trimmedTextFragment }}...
      </div>

      <form action="#" class="selected-text-ai-assistant-form__form" @submit.prevent="submitQuestion">
        <input v-model="name" class="selected-text-ai-assistant-form__name" placeholder="Your name" />

        <textarea
          v-model="question"
          class="selected-text-ai-assistant-form__comment"
          placeholder="Message to AI Assistant" />

        <button class="selected-text-ai-assistant-form__submit btn btn--lg btn--block btn--full-width" :disabled="!formIsValid">
          SEND
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@images/font_awesome/x-light.svg";
import QuoteIcon from "@images/font_awesome/quote-right-light.svg";
import { notify } from "modules/notify";
import Sentry from "modules/sentry";
import { mapGetters } from "vuex";
import { isMacOs } from "modules/is-mac-os";

export default {
  name: "SelectedTextEmailForm",
  components: {
    CloseIcon,
    QuoteIcon
  },
  computed: {
    ...mapGetters({
      userHandle: "user/userHandle"
    }),
    trimmedTextFragment() {
      return this.textFragment?.slice(0, 150);
    },
    formIsValid() {
      return this.name && this.question && this.textFragment;
    }
  },
  data() {
    return {
      name: "",
      question: null,
      textFragment: this.selectedText
    }
  },
  props: ["selectedText"],
  methods: {
    close() {
      this.textFragment = "";
      this.question = "";

      this.$emit("close");
    },
    submitQuestion() {
      const url = new URL(`${window.location.origin}/ai-coach`);

      const { contentId, contentType } = window.state.contentItem;

      url.searchParams.append("content_id", contentId);
      url.searchParams.append("content_type", contentType);
      url.searchParams.append("text_fragment", this.textFragment);
      url.searchParams.append("question", this.question);

      window.open(url.toString(), "_blank");
    },
    handleKeydown(event) {
      if(event.key === "Escape" || event.key === "Esc") {
        this.close();
      }
    },
    handleOutsideClick(event) {
      if(event.target.closest(".selected-text-ai-assistant-form") ||
        event.target.closest(".selected-text-menu") ||
        event.target.closest(".selected-text-menu-container") ||
        event.target.closest(".selected-text-menu-forms")) {

        return;
      }

      this.close();
    },
    handleCmdEnter(e) {
      if (!((isMacOs() ? e.metaKey : e.ctrlKey) && e.keyCode == 13)) {
        return;
      }

      if (!this.formIsValid) {
        return;
      }

      this.submitQuestion();
    }
  },
  mounted() {
    this.name = this.userHandle;

    document.addEventListener("keydown", this.handleKeydown);
    document.addEventListener("click", this.handleOutsideClick);
    document.addEventListener("keydown", this.handleCmdEnter);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeydown);
    document.removeEventListener("click", this.handleOutsideClick);
    document.removeEventListener("keydown", this.handleCmdEnter);
  }
}
</script>

<style lang="scss">
.selected-text-ai-assistant-form {
  position: relative;
  z-index: 2000;
  min-width: $px320;
  padding: $px48 $px8 $px8;

  box-shadow: 0 0 $px8 $grey-9;
  border: $px1 solid $grey-9;
  border-radius: $px4;

  background-color: $white;

  &__name {
    width: 100%;
    padding: $px8;
    margin-bottom: $px16;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__text-fragment {
    position: relative;
    max-width: $px320;
    margin-bottom: $px16;

    @include font-small;
    color: $grey-1;
  }

  &__comment {
    width: 100%;
    height: $px96;
    padding: $px8;
    margin-bottom: $px16;
    display: block;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__close {
    position: absolute;
    top: $px8;
    right: $px8;
    width: $px24;
    height: $px24;

    cursor: pointer;
    transition: all .25s;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
