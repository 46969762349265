<template>
  <div :class="componentClass">
    <div
      class="page-feedback__cta"
      v-if="!menuVisible && !formVisible"
      @mouseenter.prevent="menuVisible = true"
      @mouseleave.prevent="menuVisible = false">
      <CommentIcon :class="'page-feedback__comment-icon'" />
    </div>

    <div
      v-if="menuVisible"
      @mouseleave.prevent="menuVisible = false"
      class="page-feedback__menu">
      <HoverMenu
        @openEmailForm="openEmailForm"/>
    </div>

    <div v-if="formVisible" class="page-feedback__feedback">
      <div class="page-feedback__header" @click="closeAll">
        <CloseIcon :class="'page-feedback__close-icon'" />
      </div>

      <form action="#" class="page-feedback__form" @submit.prevent="submitFeedback">
        <input ref="name" type="text" class="page-feedback__name" placeholder="Your name" v-model="name" />

        <input ref="email" type="email" class="page-feedback__email" placeholder="Your email" v-model="email" required="true" v-if="!userLoggedIn" />

        <textarea ref="feedback" name="" class="page-feedback__text-area" v-model="feedback" placeholder="Message" required="true"></textarea>

        <input type="submit" class="page-feedback__submit btn btn--lg" value="Submit feedback" />
      </form>
    </div>
  </div>
</template>

<script>
import CommentIcon from "@images/font_awesome/comment-alt-smile-light.svg";
import CloseIcon from "@images/font_awesome/x-light.svg";
import HoverMenu from "components/page-feedback/hover-menu";
import axios from "axios";
import { notify } from "modules/notify";
import { getCookie, setCookie } from "modules/cookie";
import Sentry from "modules/sentry";

export default {
  name: "PageFeedback",
  components: {
    CommentIcon,
    CloseIcon,
    HoverMenu
  },
  props: {
    size: {
      type: String,
      default: "normal"
    }
  },
  data() {
    return {
      menuVisible: false,
      formVisible: false,
      name: "",
      email: window.userEmail,
      feedback: "",
      userLoggedIn: window.userEmail
    }
  },
  watch: {
    formVisible() {
      if(!this.formVisible) {
        return;
      }

      this.$nextTick(() => {
        if (this.name) {
          this.$refs.feedback.focus();
        } else {
          this.$refs.name.focus();
        }
      });
    }
  },
  computed: {
    componentClass() {
      let cssClass = "page-feedback";

      if(this.formVisible || this.menuVisible) {
        cssClass += " page-feedback--active";
      }

      if(this.size === "small") {
        cssClass += " page-feedback--small";
      }

      return cssClass;
    }
  },
  methods: {
    openEmailForm() {
      this.menuVisible = false;
      this.formVisible = true;
    },
    closeAll() {
      this.menuVisible = false;
      this.formVisible = false;
    },
    clearForm() {
      this.feedback = "";
    },
    submitFeedback() {
      let formData = new FormData();

      formData.append(
        "page_feedback[feedback]",
        `<strong>Text fragment</strong>\r\n${this.feedback}`
      );

      formData.append(
        "page_feedback[email]",
        this.email
      );

      formData.append(
        "page_feedback[name]",
        this.name
      );

      axios.post("/api/page_feedbacks", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        notify({
          message: "Thank you for the feedback!"
        });

        this.closeAll();

        setCookie("feedback-form-name", this.name, 365);

        this.clearForm();
      }).catch(function (error) {
        notify({
          message: "Smth went wrong",
          type: "error"
        });

        Sentry.captureException(error);
      });
    },
    handleEscKey(event) {
      if (event.key === "Escape" && this.formVisible) {
        this.closeAll();
      }
    }
  },
  mounted() {
    if(getCookie("feedback-form-name")) {
      this.name = getCookie("feedback-form-name");
    }

    document.addEventListener("keydown", this.handleEscKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleEscKey);
  }
}
</script>

<style lang="scss">
.page-feedback {
  $root: &;
  position: fixed;
  bottom: $px24;
  right: $px24;
  z-index: 2147483648;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: $px12;

    border-radius: $px8 $px8 0 0;

    background-color: $grey-10;

    color: $grey-1;

    cursor: pointer;

    &:hover {
      #{$root}__close-icon {
        transform: scale(1.05);
      }
    }
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $px64;
    height: $px64;
    padding: $px24 $px32 $px20;

    border-radius: 50%;

    background-color: $orange-6;

    box-shadow: 0 $px4 $px16 0 $grey-8;
    cursor: pointer;
    transition: all .1s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__feedback {
    border-radius: $px8;
    box-shadow: 0 $px4 $px16 0 $grey-8;

    transition: bottom 1s;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: $px12;

    border-radius: 0 0 $px8 $px8;

    background-color: $grey-10;
  }

  &__name {
    margin-bottom: $px16;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__email {
    margin-bottom: $px16;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__text-area {
    width: 100%;
    height: $px144;
    margin-bottom: $px16;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;

    @include breakpoint($sm) {
      width: $px320;
    }
  }

  &__close-icon {
    width: $px24;
    height: $px24;

    color: $grey-1;

    cursor: pointer;
    transition: all .2s ease-in-out;
  }

  &__comment-icon {
    width: 26px;
    height: 26px;
    flex-shrink: 0;

    color: $white;

    transform: scaleX(-1);
  }

  &--active {
    width: 100%;
    padding: 0 $px16;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint($sm) {
      width: auto;
      padding: 0;
      left: auto;
      transform: none;
    }
  }

  &--small {
    #{$root}__cta {
      width: $px48;
      height: $px48;
      padding: $px20 $px24 $px16;
    }

    #{$root}__comment-icon {
      width: $px20;
      height: $px20;
    }
  }
}
</style>
