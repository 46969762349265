import Vue from "modules/vue_twemoji";
import AuthPopup from "components/auth-popup";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const mountPopup = (cta) => {
  let afterPath = cta.dataset.afterAuth;
  let signupFormActiveProp = cta.classList.contains("js-open-signup-popup");
  const signupPopupType = cta.dataset.guestPopup;

  if(window.popup) {
    window.popup.$refs.auth_popup.show(afterPath, signupFormActiveProp, signupPopupType);
  } else {
    const $container = document.querySelector(".js-signup-popup");
    window.popup = new Vue({
      i18n: new VueI18n({
        locale: "en"
      }),
      el: $container,
      render: h => h(AuthPopup, {
        ref: "auth_popup",
        props: {
          visible: true,
          afterAuthProp: afterPath,
          signupFormActiveProp: signupFormActiveProp,
          signupPopupType
        }
      })
    });
  }
}

document.addEventListener("click", e => {
  const $cta = e?.target?.closest(".js-open-signup-popup, .js-open-login-popup");

  if($cta) {
    e.preventDefault();

    mountPopup($cta);
  }
});

document.addEventListener("click", (e) => {
  if(!e.target.classList) {
    return;
  }

  if(e.target.closest(".js-open-signup-popup")) {
    return;
  }

  if(e.target.closest(".js-open-login-popup")) {
    return;
  }

  if(!window.popup) {
    return;
  }

  if(window.popup.$refs && window.popup.$refs.auth_popup && !window.popup.$refs.auth_popup.isVisible()) {
    return;
  }

  const $authPopup = window.popup.$refs.auth_popup.$el.querySelector(".auth-popup");
  let targetElement = e.target;

  do {
    if (targetElement == $authPopup) {
      return;
    }

    if(targetElement.classList && targetElement.classList.contains("signup-popup")) {
      return;
    }

    if(targetElement.classList && targetElement.classList.contains("login-popup")) {
      return;
    }

    targetElement = targetElement.parentNode;
  } while (targetElement);

  e.preventDefault();

  window.popup.$refs.auth_popup.hide();
});

document.addEventListener("keydown", function(e) {
  if(e.key !== "Escape" && e.key !== "Esc") {
    return;
  }

  if(!window.popup) {
    return;
  }

  if(!window.popup.$refs.auth_popup.isVisible()) {
    return;
  }

  window.popup.$refs.auth_popup.hide();
});
