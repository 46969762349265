<template>
  <div
    :class="blockClass">
    <InfoIcon class="tooltip__icon" />

    <div class="tooltip__copy">
      {{ copy }}
    </div>
  </div>
</template>

<script>
import InfoIcon from "@images/font_awesome/info-circle-light.svg";
import { bemClass } from "helpers/bem-class";

export default {
  name: "Tooltip",
  components: {
    InfoIcon
  },
  props: {
    copy: {
      type: String,
      required: true
    },
    modifiers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    blockClass() {
      return bemClass({
        baseClass: "tooltip",
        modifiers: this.modifiers
      });
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  $root: &;

  display: inline-flex;
  align-self: center;
  position: relative;

  &__icon {
    width: $px16;
    height: $px16;
  }

  &__copy {
    width: $px240;
    padding: $px8;
    position: absolute;
    top: -$px12;
    left: 50%;
    visibility: hidden;
    z-index: 99999999999;
    opacity: .40;

    border-radius: $px4;

    background: $grey-3;

    color: $white;
    @include font-small;

    transform: translate(-50%, -100%);
    transition: all 0.25s ease-in;

    box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);

    &:after {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
      width: 0;
      height: 0;

      border-style: solid;
      border-width: 7px 7.5px 0 7.5px;
      border-color: $grey-3 transparent transparent transparent;

      content: " ";
    }
  }

  &:hover {
    #{$root}__copy {
      visibility: visible;

      opacity: 1;
      transition: .1s linear;
    }
  }

  &--small {
    #{$root}__icon {
      width: $px12;
      height: $px12;
    }
  }
}
</style>
