import Vue from "modules/vue_twemoji";
import MockInterview from "components/mock-interview";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const $mockInterviewContainer = document.querySelector(".js-mock-interview");

if($mockInterviewContainer) {
  new Vue({
    i18n: new VueI18n({
      locale: "en"
    }),
    el: $mockInterviewContainer,
    render: h => h(MockInterview)
  });
}
