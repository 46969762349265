import Vue from "modules/vue_twemoji";
import VueI18n from "vue-i18n";
import { store } from "store";

const $container = document.querySelector(".js-ai-chat");

if ($container) {
  const {
    aiApiHost,
    contentName,
    contentId,
    contentType,
    contentUrl,
    datasetSlug,
    datasets,
    showWelcomeMessage,
    aiCoachVersion
  } = $container.dataset;

  import("components/ai-chat").then(({ default: AiChat }) => {
    const parsedDatasets = JSON.parse(datasets).reduce((acc, [id, slug, name]) => {
      acc[slug] = { id, name };
      return acc;
    }, {});

    new Vue({
      el: $container,
      i18n: new VueI18n({
        locale: "en",
        silentTranslationWarn: process.env.NODE_ENV === "production"
      }),
      store,
      render: h => h(AiChat, {
        props: {
          apiHost: aiApiHost,
          contentId: parseInt(contentId, 10),
          contentType,
          contentName,
          contentUrl,
          datasetSlug,
          datasets: parsedDatasets,
          showWelcomeMessage: showWelcomeMessage === "true",
          aiCoachVersion
        }
      })
    });
  });
}
